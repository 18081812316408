import "./loginForm.scss";
import { FC, useState } from "react";
import { useForm } from "@shared/model";
import { IFormLoginState, ROUTES, IDataLoginResponse, ICustomNotification } from "@shared/interfaces";
import { ButtonStyled, CheckboxStyled } from "@shared/ui/components";
import { Alert, Input, Typography, notification } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import onLoginFormValid from "../../model/loginValidationHook";
import { useUserStore } from "@entities/user";
import { errorHandler } from "@shared/utils";
import type { NotificationArgsProps } from 'antd';

type NotificationPlacement = NotificationArgsProps['placement'];

const { Link, Title } = Typography;

const user: IFormLoginState = {
  email: "",
  password: "",
};

const notificationState: ICustomNotification = {
 condition: false,
 text: "",
};

const LoginForm: FC = () => {
  const { logIn, setUser } = useUserStore((state) => state);
  const [alert, setAlert] = useState<ICustomNotification>(notificationState);
  const [api, contextHolder] = notification.useNotification();
  const [activeFormError, setActiveFormError] = useState<
    Record<string, string>
  >({});

  const { formData, onChangeInput, onSubmitForm, resetFormData } =
    useForm<IFormLoginState>(user, handlerBeforeSubmitForm);

  async function handlerBeforeSubmitForm() {
    const formErrors = onLoginFormValid<IFormLoginState>(formData);
    const arrayOfErrors = Object.keys(formErrors);

    setAlert(notificationState);
    
    if (arrayOfErrors.length) {
      return setActiveFormError(formErrors);
    }

    setActiveFormError({});
    setAlert({condition: false, text: ""});

    try {
      await logIn(formData);
    } catch (e: any) {
      if (e.isHandled) {
        return setAlert({condition: true, text: e.text});
      }
      openNotification('bottom', e.text);
    }
  }

  const openNotification = (placement: NotificationPlacement, message: string) => {
    api.info({
      duration: 5,
      message: message,
      placement,
      className: "notification__wrapper",
      closeIcon: false,
    });
  };

  const onChange = (event: CheckboxChangeEvent) => {
    console.log(event.target.checked);
  };

  return (
    <div>
      {contextHolder}
      <form onSubmit={onSubmitForm} className="loginform__wrapper">
        <div className="form__item form-item__mb24">
          <label className="" htmlFor={"email"}>
            Email
          </label>

          <Input
            id="email"
            className="gray-primary-900"
            name="email"
            type="text"
            onChange={onChangeInput}
            value={formData.email}
            status={activeFormError.email && "error"}
          />
          {activeFormError.email && (
            <Title type="danger" level={5}>
              {activeFormError.email}
            </Title>
          )}
        </div>

        <div className="form__item">
          <label className="" htmlFor={"password"}>
            Password
          </label>

          <Input.Password
            id="password"
            className="gray-primary-900"
            name="password"
            type="password"
            onChange={onChangeInput}
            value={formData.password}
            status={activeFormError.password && "error"}
          />
          {activeFormError.password && (
            <Title type="danger" level={5}>
              {activeFormError.password}
            </Title>
          )}
        </div>
        {/* <div className="login-forgot__wrapper">
          <Link href={ROUTES.FORGOT_PASSWORD}>Forgot password?</Link>
        </div> */}

        {alert.condition && (
          <Alert message={alert?.text || ""} type="error" showIcon />
        )}

        {/* <div className="login-remember__wrapper">
          <CheckboxStyled
            text="Remember me"
            onChange={onChange}
            fill="gray-primary-900"
          />
        </div> */}

        <ButtonStyled
          text="Log in"
          className="mt-24"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
        />
      </form>
    </div>
  );
};

export default LoginForm;
