import "./LoginWidget.scss";
import { LoginForm } from "@widgets/auth/index";
import { Typography } from "antd";
import { ROUTES } from "@shared/interfaces";
import { FC } from "react";

const { Title, Link } = Typography;

const LoginWelcome: FC = () => {
  return (
    <div className="login__container">
      <Title level={1} className="login__title">
        Relu
      </Title>
      <Title level={1} className="login__subtitle">
        Welcome!
      </Title>
      <Title level={4} className="login__text">
        Log in into your Relu account
      </Title>
      <LoginForm />

      {/* <Title level={4} className="login-terms__text login-account__text">
        Don’t have an account?
        <Link href={ROUTES.SIGN_IN}>Sign up</Link>
      </Title> */}
    </div>
  );
};

export default LoginWelcome;
