import "./groupsItemsWidget.scss";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import { ButtonStyled } from "@shared/ui/components";
import { Delete, Plus } from "@shared/ui/assets";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ItemsPopup } from "../../../ItemsPopup";
import { useShallow } from "zustand/react/shallow";
import { IProduct } from "@entities/products";
import {
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DIRECTION_PARAM,
  GROUP_ID_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
} from "@shared/constants";
import { generateTextColumn, Pagination } from "@shared/common";
import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import { Checkbox, Modal } from "antd";
import {
  cutStringToLimit,
  filteredCompareQueryObj,
  getItemWord,
} from "@shared/utils";
import {
  AlertType,
  CurrentStep,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";

enum THEAD_SORT_KEYS {
  NAME = "name",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
  COST = "cost",
  BRAND = "brand",
  SUBCATEGORY = "subcategory",
  STORE = "store",
}

const GroupsItemsWidget = () => {
  let { id } = useParams();
  const [isItemsPopupShow, setItemsPopupShow] = useState<boolean>(false);
  const [isModalInfoOpen, setIsModalInfoOpen] = useState<boolean>(false);
  const productsInGroup = useGroupsStore((state) => state.productsInGroup);
  const { selectedCategory } = useGroupsStore((state) => state.currentGroup);

  const {
    strategy: { type, strategyRule, priceLimits },
  } = useGroupsStore((state) => state);
  const { pricesFormula } = strategyRule;
  const {
    selectProductInGroup,
    selectAllProductsInGroup,
    deleteProductsInGroup,
    setProductsInGroup,
    getAllExceptions,
  } = useGroupsStore(useShallow((state) => state));
  const {
    getProductsInGroup,
    addAlertToStore,
    removeSelectProductsIdsInGroup,
    allExceptions,
  } = useGroupsStore((state) => state);
  const productsInGroupSelectedIds = useGroupsStore(
    useShallow((state) => state.productsInGroupSelectedIds)
  );
  const recordsCount = useGroupsStore(
    useShallow((state) => state.recordsCountInGroup)
  );
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);
  const isAllSelected = productsInGroup.every((product) =>
    productsInGroupSelectedIds.includes(product.id)
  );

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | null;
  }>({
    key: "",
    direction: null,
  });

  useEffect(() => {
    const data = {
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction,
      [LIMIT_PARAM]: currentLimit,
      [OFFSET_PARAM]: currentOffset,
      [GROUP_ID_PARAM]: id,
    };

    const filteredData = filteredCompareQueryObj(data) as IDataTransfer;
    if (!isItemsPopupShow) {
      getProductsInGroup(filteredData);
    }
  }, [sortConfig, isItemsPopupShow, currentLimit, currentOffset]);

  useEffect(() => {
    return () => {
      setProductsInGroup([]);
      removeSelectProductsIdsInGroup();
    };
  }, []);

  const defineCurrentOffset = () => {
    const res =
      productsInGroup.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const deleteSelectedProducts = async () => {
    if (!id) {
      return;
    }
    await deleteProductsInGroup(+id);
    removeSelectProductsIdsInGroup();
    const params = {
      [GROUP_ID_PARAM]: id,
    };

    await getProductsInGroup({ ...params });
  };

  const headDataTable = [
    {
      content: (
        <Checkbox
          checked={isAllSelected && !!productsInGroup.length}
          onChange={(event) => {
            selectAllProductsInGroup(event.target.checked);
          }}
        ></Checkbox>
      ),
      className: "w-48",
    },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-380",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "w-71",
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        // isSorted: true,
        // sortDirection: sortConfig.direction,
        // isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "w-109",
      // sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "w-120",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    {
      content: generateTextColumn({
        text: "Subcategory",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.SUBCATEGORY === sortConfig.key,
      }),
      className: "w-140",
      sortKey: THEAD_SORT_KEYS.SUBCATEGORY,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "w-95",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ isSelected, id }: IProduct) => (
        <Checkbox
          checked={productsInGroupSelectedIds.some((el) => el === id)}
          onChange={(event) => {
            selectProductInGroup(id, event?.target.checked);
          }}
        ></Checkbox>
      ),
    },
    {
      content: ({ article, name }: IProduct) => (
        <>
          <h5 className="product__subtitle">{article}</h5>
          <h5 className="product__title">{cutStringToLimit(name)}</h5>
        </>
      ),
      className: "w-430",
    },
    {
      content: ({ imageUrl }: IProduct) => (
        <div>
          <img alt="img-url" className="product__img" src={imageUrl} />
        </div>
      ),
    },
    {
      content: ({ currentPrice }: IProduct) => (
        <h5 className="ta-right">${currentPrice}</h5>
      ),
    },
    {
      content: ({ currentMargin = "0" }: IProduct) => <h5>{currentMargin}%</h5>,
      className: "ta-right",
    },
    {
      content: ({ cost }: IProduct) => <h5>${cost}</h5>,
    },
    {
      content: ({ brand }: IProduct) => <h5>{brand}</h5>,
    },
    {
      content: ({ subcategory }: IProduct) => <h5>{subcategory}</h5>,
    },
    {
      content: ({ store }: IProduct) => <h5>{store}</h5>,
    },
  ];

  const dataTableBody = productsInGroup.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: productsInGroupSelectedIds.some((id) => id === item.id),
      className: el.className,
      item: item,
    }));
  });

  const addItemsBtn = () => {
    setItemsPopupShow(true);
  };

  const handleOk = async () => {
    const isError =
      strategyRule.competitors.length ||
      pricesFormula.metricToGrow ||
      pricesFormula.metricToMaintain;

    try {
      await deleteSelectedProducts();
      setIsModalInfoOpen(false);
      if (productsInGroupSelectedIds.length === recordsCount) {
        useGroupsStore.setState((state) => ({
          ...state,
          currentGroup: {
            ...state.currentGroup,
            selectedCategory: "",
          },
          strategy: {
            ...state.strategy,
            [CurrentStep.STRATEGY_RULE]: {
              ...state.strategy[CurrentStep.STRATEGY_RULE],
              alerts: isError
                ? addAlertToStore("strategyRule", AlertType.WARNING)
                : [],
              pricesFormula: {
                value: "",
                rate: "dollar",
                metric: "plus",
              },
              competitors: [],
            },
          },
        }));
      }

      const data = {
        [GROUP_ID_PARAM]: id,
      };
      const exceptionsRes = await getAllExceptions(data);

      if (exceptionsRes) {
        const isWarningLimits = checkLengthLimitsForExceptions(exceptionsRes);

        if (isWarningLimits) {
          addAlertToStore("priceLimits", AlertType.WARNING);
        } else {
          useGroupsStore.setState((state) => {
            return {
              ...state,
              strategy: {
                ...state.strategy,
                priceLimits: {
                  ...state.strategy.priceLimits,
                  alerts: [],
                },
              },
            };
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancel = () => {
    setIsModalInfoOpen(false);
  };

  const isCorrectDiapason = (currentMargin: number) => {
    const { max, min } = priceLimits;
    const maxPricingValue = +max?.pricingValue || "";
    const minPricingValue = +min?.pricingValue || "";

    if (
      max?.pricing === SelectLimitPricing.MARGIN &&
      min?.pricing === SelectLimitPricing.MARGIN
    ) {
      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        minPricingValue > maxPricingValue
      ) {
        return false;
      }

      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        currentMargin > maxPricingValue &&
        currentMargin < minPricingValue
      ) {
        return true;
      }
    }
    if (max?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof maxPricingValue === "number" &&
        currentMargin > maxPricingValue
      ) {
        return true;
      } else return false;
    }
    if (min?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof minPricingValue === "number" &&
        currentMargin < minPricingValue
      ) {
        return true;
      } else return false;
    }

    return false;
  };

  const checkLengthLimitsForExceptions = (allExceptions: any) => {
    return allExceptions.filter((el: any) => {
      return isCorrectDiapason(+el.currentMargin);
    }).length;
  };

  const handleChange = async () => {
    const isError =
      strategyRule.competitors.length ||
      pricesFormula.metricToGrow ||
      pricesFormula.metricToMaintain;
    if (type) {
      setIsModalInfoOpen(true);
    } else {
      await deleteSelectedProducts();

      if (productsInGroupSelectedIds.length === recordsCount) {
        useGroupsStore.setState((state) => ({
          ...state,
          currentGroup: {
            ...state.currentGroup,
            selectedCategory: "",
          },
          strategy: {
            ...state.strategy,
            [CurrentStep.STRATEGY_RULE]: {
              ...state.strategy[CurrentStep.STRATEGY_RULE],
              alerts: isError
                ? addAlertToStore("strategyRule", AlertType.WARNING)
                : [],
              pricesFormula: {
                value: "",
                rate: "dollar",
                metric: "plus",
              },
              competitors: [],
            },
          },
        }));
      }
    }
  };

  const infoTextList = [
    {
      text: "Remove the items from the current group and leave them ungrouped",
    },
    {
      text: 'Potentially affect your current settings in the "Strategy Rule" and "Price Limits" tabs',
    },
  ];

  const modalInfoRender = () => (
    <Modal
      title={<div className="modal__title">Remove Items</div>}
      open={isModalInfoOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      closable={true}
      maskClosable={false}
      footer={[
        <div key={1} className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={handleCancel}
            />
            <ButtonStyled
              text="Delete"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-84"
              onClick={handleOk}
            />
          </div>
        </div>,
      ]}
    >
      <div className="confirm-modal-body-info">
        Are you sure yo u want to remove
        <b>
          {` ${productsInGroupSelectedIds.length}`} selected{" "}
          {getItemWord(productsInGroupSelectedIds.length)}
        </b>
        ?
        <div>
          <span>This action will:</span>
          <ul>
            {infoTextList.map((el, ind) => (
              <li key={ind}>{el.text}</li>
            ))}
          </ul>
        </div>
        After removing the items, you may need to review and adjust these
        settings.
      </div>
    </Modal>
  );

  const renderChoose = () => {
    if (!productsInGroup.length) {
      return (
        <DefaultNoItemsValue
          isItemsPopupShow={isItemsPopupShow}
          setItemsPopupShow={setItemsPopupShow}
        />
      );
    }
    return (
      <>
        <ItemsPopup
          flag={isItemsPopupShow}
          setItemsPopupShow={setItemsPopupShow}
        />
        {modalInfoRender()}
        <div className="groups-edite__wrapper">
          <h3 className="groups-edite__title">Product Items</h3>
          <h5 className="groups-edite__suptitle">Add items to your group</h5>
          <div>
            <div className="groups-header df jc-sb">
              <div>
                <b>{`${recordsCount} ${getItemWord(
                  productsInGroup.length
                )}`}</b>
                {` from 
        ${selectedCategory} category`}
              </div>
              <div>
                <ButtonStyled
                  icon={<Plus color="#212529" />}
                  text="Add items"
                  htmlType="button"
                  onClick={() => addItemsBtn()}
                />
              </div>
            </div>
            <div className="groups-edite-table">
              <TableCustom
                headData={headDataTable}
                bodyData={dataTableBody}
                handleSort={handleSort}
              />
            </div>
          </div>
          <>
            <div className="pagination__container">
              {productsInGroupSelectedIds.length ? (
                <div className="selected__block mr-24">
                  <span className="selected__count">{`${productsInGroupSelectedIds.length}`}</span>
                  <div className="selected__text">
                    {`${getItemWord(
                      productsInGroupSelectedIds.length
                    )} selected`}
                  </div>

                  <div className="groups-block-delete__btn">
                    <ButtonStyled
                      type="default"
                      icon={<Delete />}
                      text="Remove"
                      htmlType="button"
                      fill="danger_300"
                      className="groups-delete__btn"
                      onClick={handleChange}
                    />
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <Pagination
                prevPage={definePrefPage}
                nextPage={defineNextPage}
                onChange={onChangeItemsCount}
                recordsCount={definePageCount()}
                currentLimit={defineCurrentLimit()}
                currentSelectLimit={currentLimit.toString()}
                currentOffset={defineCurrentOffset()}
              />
            </div>
          </>
        </div>
      </>
    );
  };

  return <>{renderChoose()}</>;
};

const DefaultNoItemsValue = ({
  isItemsPopupShow,
  setItemsPopupShow,
}: {
  isItemsPopupShow: boolean;
  setItemsPopupShow: (bool: boolean) => void;
}) => {
  const addItems = () => {
    setItemsPopupShow(true);
  };

  return (
    <div className="groups-empty__wrapper">
      <ItemsPopup
        flag={isItemsPopupShow}
        setItemsPopupShow={setItemsPopupShow}
      />
      <h4 className="groups-empty__title">No items added</h4>
      <h4 className="groups-empty__subtitle">
        Add items to start implementing pricing strategies
      </h4>
      <ButtonStyled
        type="primary"
        icon={<Plus />}
        text="Add items"
        htmlType="button"
        fill="gray-primary-900"
        className="groups-empty__btn"
        onClick={addItems}
      />
    </div>
  );
};
export default GroupsItemsWidget;
