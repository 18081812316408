import { ITableRowData, IThead } from "@shared/interfaces";
import React from "react";
import { FC, memo, ReactElement } from "react";
import "./tableCustom.scss";

interface ITableCustom {
  headData: IThead[];
  bodyData?: ITableRowData[][] | any;
  handleSort?: (key: string) => void;
  onRowEvent?: (item: any) => void;
  expandedRowRender?: (item: any) => ReactElement | null;
  tableClassName?: string;
  noDataText?: string;
}

const TableCustom: FC<ITableCustom> = (props) => {
  const {
    headData,
    bodyData,
    onRowEvent,
    handleSort,
    expandedRowRender,
    tableClassName,
    noDataText,
  } = props;
  const onClick = (item: any) => {
    if (onRowEvent && item) {
      onRowEvent(item);
    }
  };

  const styleCheckedRow = (item: any) =>
    item[0]?.isSelected ? "tr-selected" : "";
  const styleExpandedRow = (item: any) =>
    item[0]?.isExpanded ? "tr-expanded" : "";
  return (
    <table className={`relu__table gropus__table ${tableClassName}`}>
      <thead>
        <tr className="items__header">
          {headData.map(({ className, content, sortKey = "" }, ind: number) => {
            return (
              <th
                key={ind}
                className={className}
                onClick={() => sortKey && handleSort && handleSort(sortKey)}
              >
                {content}
              </th>
            );
          })}
        </tr>
      </thead>
      {!noDataText ? (
        <tbody>
          {bodyData &&
            bodyData.map((item: ITableRowData[], index: number) => {
              return (
                <React.Fragment key={`tr-${index}`}>
                  <tr
                    className={`${styleCheckedRow(item)} ${styleExpandedRow(
                      item
                    )}`}
                    onClick={() => onClick(item[0]?.item)}
                  >
                    {item.map((el: ITableRowData, ind: number) => (
                      <td
                        key={ind}
                        className={
                          typeof el.className === "function"
                            ? el.className()
                            : el?.className
                        }
                      >
                        {el.element}
                      </td>
                    ))}
                  </tr>
                  {/* Рендерим дополнительный ряд, если он развернут */}
                  {expandedRowRender && expandedRowRender(item[0].item)}
                </React.Fragment>
              );
            })}
        </tbody>
      ) : (
        <div className="no-data-table-block">
          <div className="no-data-table__text"> {noDataText}</div>
        </div>
      )}
    </table>
  );
};

export default memo(TableCustom);
