import { FC, useEffect, useMemo, useState } from "react";
import "./layoutWithStepper.scss";
import { ButtonStyled } from "@shared/ui/components";
import {
  Arrow,
  ArrowRight,
  CheckOval,
  Warning,
  Error,
} from "@shared/ui/assets";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "@shared/interfaces";
import { StepProps, Steps } from "antd";
import {
  AlertType,
  CurrentStep,
  IAlert,
  IGroupState,
  StrategyTypeEnum,
  useGroupsStore,
} from "@entities/groups";
import {
  GroupsItemsWidget,
  GroupsOnCancelNotificatioPopup,
  NameSummary,
  PriceLimits,
  PriceRounding,
  StrategyRule,
  StrategyType,
  UpdatePolicy,
} from "@widgets/admin/groups";
import useValidationStepper, {
  IValidationExceptionItem,
} from "@shared/model/hooks/useValidationSteper";
import { GROUP_ID_PARAM } from "@shared/constants";

const PROCESS = "process";
const FINISH = "finish";
const WAIT = "wait";
const ERROR = "error";

const STEP_KEYS = [
  CurrentStep.PRODUCT_ITEM,
  CurrentStep.STRATEGY_TYPE,
  CurrentStep.STRATEGY_RULE,
  CurrentStep.PRICE_LIMITS,
  CurrentStep.PRICE_ROUNDING,
  CurrentStep.UPDATE_POLICY,
  CurrentStep.SUMMARY,
];

const WarningIcon = () => <Warning />;
const ErrorIcon = () => <Error />;

const LayoutWithStepper: FC = () => {
  const STEPS: StepProps[] = [
    {
      title: "Product Items",
    },
    {
      title: "Strategy Type",
    },
    {
      title: "Strategy Rule",
    },
    {
      title: "Price Limits",
    },
    {
      title: "Price Roundings",
    },
    {
      title: "Update Policy",
    },
    {
      title: "Name & Summary",
    },
  ];
  const navigate = useNavigate();
  const { id } = useParams();
  const currentStepStore = useGroupsStore(
    (state: IGroupState) => state.strategy.currentStep
  );
  const { getAllExceptions } = useGroupsStore((state: IGroupState) => state);

  const {
    setCurrentStep,
    getGroup,
    setCompetitorsInGroup,
    setValidationError,
    validationErrors,
    clearStrategyInStore,
  } = useGroupsStore((state: IGroupState) => state);
  const strategy = useGroupsStore((state) => state.strategy);
  const currentGroup = useGroupsStore((state) => state.currentGroup);
  const productsInGroup = useGroupsStore((state) => state.productsInGroup);
  const { updateStrategy, removeGroup, addAlertToStore, updateGroupName } =
    useGroupsStore((state) => state);

  const [steps, setSteps] = useState<StepProps[]>(STEPS);
  const [isCancelConfirmationShow, setCancelConfirmationShow] =
    useState<boolean>(false);

  const activeStep = STEP_KEYS.indexOf(currentStepStore);

  const [isHighlighted, setHighlighted] = useState<number>(0);
  const {
    isErrorValidPriceLimitsMinInput,
    isErrorValidPriceLimitsMinSelect,
    isErrorValidPriceLimitsMaxInput,
    isErrorValidPriceLimitsMaxSelect,
    isErrorValidPriceLimitsInputs,
    arrValidationExceptions,
    isErrorValidDecimalInput,
    isErrorValidIntegerInput,
    isErrorValidNameSummaryInput,
  } = useValidationStepper();

  useEffect(() => {
    setHighlighted(activeStep);
  }, [activeStep]);

  const onChange = (value: number) => {
    if (value > activeStep) {
      return;
    }

    setHighlighted(value);
  };

  useEffect(() => {
    const data = {
      [GROUP_ID_PARAM]: id,
    };
    getAllExceptions(data);
    return () => {
      setCurrentStep(STEP_KEYS[0]);
      addAlertToStore("strategyRule", "");
    };
  }, []);

  useEffect(() => {
    if (strategy.id) {
      updateStrategy();
    }
  }, [strategy]);

  const updateStepsFunc = () => {
    const updatedSteps = STEPS.map((item, index) => {
      const subObj = validationErrors[STEP_KEYS[index]];

      const isValidationError =
        STEP_KEYS[index] &&
        subObj &&
        Object.values(subObj).some((value) => !!value);

      const alertsLocal: IAlert[] = strategy[STEP_KEYS[index]]?.alerts
        ? [...strategy[STEP_KEYS[index]]?.alerts]
        : [];

      if (isValidationError) {
        alertsLocal.unshift({ type: AlertType.ERROR, priority: 0 });
      }

      const customAlertError =
        (strategy && alertsLocal[0]?.type === AlertType.ERROR) || false;
      const customAlertWarning =
        (strategy && alertsLocal[0]?.type === AlertType.WARNING) || false;

      if (index < activeStep) {
        item.status = FINISH;
        item.icon = <CheckOval />;
        item.title = STEPS[index].title;
      } else if (index === activeStep) {
        item.status = PROCESS;
        item.icon = null;
        item.onClick = () => onChange(activeStep);
        item.title = STEPS[index].title;
      } else {
        item.status = WAIT;
        item.icon = "";
        item.title = STEPS[index].title;
      }

      if (customAlertError) {
        const iconToAlert = alertsLocal[0].type === AlertType.ERROR && (
          <ErrorIcon />
        );
        item.title = (
          <div className="item__title-wrapper-wait">
            {STEPS[index].title}
            <div className="item__icon-warning">{iconToAlert}</div>
          </div>
        );
      }
      if (customAlertWarning) {
        const iconToAlert = <WarningIcon />;
        item.title = (
          <div className="item__title-wrapper-wait">
            {STEPS[index].title}
            <div className="item__icon-warning">{iconToAlert}</div>
          </div>
        );
      }

      item.className = isHighlighted === index ? "item__highlighted" : "";

      return item;
    });

    setSteps(updatedSteps);
  };

  useEffect(() => {
    updateStepsFunc();
  }, [
    strategy,
    strategy.strategyRule.alerts,
    activeStep,
    isHighlighted,
    getGroup,
    currentGroup,
    currentGroup.id,
    validationErrors[CurrentStep.PRICE_LIMITS],
    validationErrors[CurrentStep.SUMMARY],
  ]);

  const renderChildren = () => {
    const currentStepLocal = STEP_KEYS[isHighlighted];
    switch (currentStepLocal) {
      case CurrentStep.PRODUCT_ITEM:
        return <GroupsItemsWidget />;
      case CurrentStep.STRATEGY_TYPE:
        return <StrategyType />;
      case CurrentStep.STRATEGY_RULE:
        return <StrategyRule />;
      case CurrentStep.PRICE_LIMITS:
        return <PriceLimits />;
      case CurrentStep.PRICE_ROUNDING:
        return <PriceRounding />;
      case CurrentStep.UPDATE_POLICY:
        return <UpdatePolicy />;
      case CurrentStep.SUMMARY:
        return <NameSummary />;
      default:
        return null;
    }
  };

  const checkExceptionsValidation = () => {
    const currentValidationErrors =
      useGroupsStore.getState().validationErrors[CurrentStep.PRICE_LIMITS];

    if (!arrValidationExceptions.length) {
      setValidationError({
        [CurrentStep.PRICE_LIMITS]: {
          ...currentValidationErrors,
          exceptionProducts: null,
        },
      });
      return true;
    }

    const updatedExceptionProducts: { [key: string]: any } = {
      ...currentValidationErrors?.exceptionProducts,
    };

    const validationStoreArr: { [key: string]: any } = {};

    arrValidationExceptions.forEach((el: IValidationExceptionItem) => {
      const { id, ...rest } = el;

      const validationException = {
        ...updatedExceptionProducts[el.id],
        ...rest,
      };
      validationStoreArr[id] = validationException;
    });

    setValidationError({
      [CurrentStep.PRICE_LIMITS]: {
        ...currentValidationErrors,
        exceptionProducts: {
          ...validationStoreArr,
        },
      },
    });

    return false;
  };

  const checkPriseLimitsValidation = () => {
    let isValid = true;

    const errorConfigs = [
      { error: isErrorValidPriceLimitsMinSelect, field: "minSelect" },
      {
        error: isErrorValidPriceLimitsMinInput,
        field: "minInput",
        key: "value",
      },
      { error: isErrorValidPriceLimitsMaxSelect, field: "maxSelect" },
      {
        error: isErrorValidPriceLimitsMaxInput,
        field: "maxInput",
        key: "value",
      },
      {
        error: isErrorValidPriceLimitsInputs,
        field: "maxInput",
        key: "range",
      },
      {
        error: isErrorValidPriceLimitsInputs,
        field: "minInput",
        key: "range",
      },
    ];

    const newErrors: { [key: string]: any } = {};

    errorConfigs.forEach(({ error, field, key }) => {
      const errorData = error ? { priority: 0, type: AlertType.ERROR } : null;

      if (errorData) {
        if (key) {
          newErrors[field] = {
            [key]: errorData,
          };
        } else {
          newErrors[field] = errorData;
        }
        isValid = false;
      }
    });

    setValidationError({
      [CurrentStep.PRICE_LIMITS]: newErrors,
    });

    return isValid;
  };

  const checkNameAndSummaryValidation = () => {
    let isValid = true;
    const errorConfigs = [
      { error: isErrorValidNameSummaryInput, field: "name" },
    ];

    const newErrors: { [key: string]: any } = {};

    errorConfigs.forEach(({ error, field }) => {
      if (error) {
        newErrors[field] = { priority: 0, type: AlertType.ERROR };
        isValid = false;
      }
    });

    setValidationError({
      [CurrentStep.SUMMARY]: Object.keys(newErrors).length ? newErrors : null,
    });

    return isValid;
  };
  const checkPriceRoundingValidation = () => {
    let isValid = true;

    const errorConfigs = [
      { error: isErrorValidDecimalInput, field: "decimal" },
      { error: isErrorValidIntegerInput, field: "integer" },
    ];

    const newErrors: { [key: string]: any } = {};

    errorConfigs.forEach(({ error, field }) => {
      if (error) {
        newErrors[field] = { priority: 0, type: AlertType.ERROR };
        isValid = false;
      } else {
        newErrors[field] = null;
      }
    });

    setValidationError({
      [CurrentStep.PRICE_ROUNDING]: isValid
        ? null
        : {
            ...useGroupsStore.getState().validationErrors[
              CurrentStep.PRICE_ROUNDING
            ],
            ...newErrors,
          },
    });

    return isValid;
  };

  const checkValidation = () => {
    let isValidPriseLimits = true;
    let exceptionsValidation = true;
    let priceRoundingValidation = true;
    let summaryValidation = true;
    if (isActiveStep(CurrentStep.PRICE_LIMITS)) {
      isValidPriseLimits = checkPriseLimitsValidation();
      exceptionsValidation = checkExceptionsValidation();
    }
    if (isActiveStep(CurrentStep.PRICE_ROUNDING)) {
      priceRoundingValidation = checkPriceRoundingValidation();
    }
    if (isActiveStep(CurrentStep.SUMMARY)) {
      summaryValidation = checkNameAndSummaryValidation();
    }

    return (
      isValidPriseLimits &&
      exceptionsValidation &&
      priceRoundingValidation &&
      summaryValidation
    );
  };

  const onNextStep = () => {
    const isValid = checkValidation();

    if (isValid) {
      if (isActiveStep(CurrentStep.PRICE_LIMITS)) {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: null,
        });
      }
      if (isActiveStep(CurrentStep.PRICE_ROUNDING)) {
        setValidationError({
          [CurrentStep.PRICE_ROUNDING]: null,
        });
      }
      // after validation
      setHighlighted(isHighlighted + 1);
      if (isHighlighted === activeStep) {
        setCurrentStep(STEP_KEYS[activeStep + 1]);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getGroup(id);
    }
    return () => {
      setCompetitorsInGroup([]);
    };
  }, []);

  const onPreviousStep = () => {
    const newHighlightedIndex = activeStep - isHighlighted + 1;
    if (steps.length > newHighlightedIndex && isHighlighted > 0) {
      setHighlighted(activeStep - newHighlightedIndex);
    }
  };

  const onCancel = () => {
    setCancelConfirmationShow(true);
  };

  const cancelApprove = async () => {
    if (id) {
      await removeGroup(id);
      navigate(ROUTES.ADMIN_GROUPS);
      clearStrategyInStore();
    }
  };

  const isActiveStep = (step: CurrentStep) => {
    return step === STEP_KEYS[isHighlighted];
  };

  const isDisabledNextBtn = () => {
    const {
      competitors,
      pricesFormula: { type, metricToGrow, metricToMaintain },
    } = strategy.strategyRule;
    if (productsInGroup.length === 0 && activeStep === 0) {
      return true;
    }
    // STRATEGY_TYPE
    if (isActiveStep(CurrentStep.STRATEGY_TYPE) && !strategy.type) {
      return true;
    }
    // CurrentStep.STRATEGY_RULE
    if (
      isActiveStep(CurrentStep.STRATEGY_RULE) &&
      strategy.type === StrategyTypeEnum.COMPETITION &&
      (!competitors.length || !type)
    ) {
      return true;
    }
    if (
      isActiveStep(CurrentStep.STRATEGY_RULE) &&
      strategy.type === StrategyTypeEnum.ELASTICITY &&
      !metricToGrow &&
      !metricToMaintain
    ) {
      return true;
    }
    return false;
  };

  const checkNameOfGroup = async () => {
    let isValidNameOfGroup = true;
    try {
      await updateGroupName(currentGroup.name);
      setValidationError({
        [CurrentStep.SUMMARY]: null,
      });
    } catch (error: any) {
      setValidationError({
        [CurrentStep.SUMMARY]: {
          name: {
            priority: 0,
            type: AlertType.ERROR,
          },
          textError: error?.text,
        },
      });
      isValidNameOfGroup = false;
    }
    return isValidNameOfGroup;
  };

  const checkAlertsErrors = () => {
    return !STEP_KEYS.some((keyOfStrategy, index) => {
      return strategy[keyOfStrategy]?.alerts?.some((alert: IAlert) => {
        return alert.type === AlertType.ERROR;
      });
    });
  };

  const handleClickComplete = async () => {
    const isValid = checkNameAndSummaryValidation();
    const isValidPriseLimits = checkPriseLimitsValidation();
    const exceptionsValidation = checkExceptionsValidation();
    const priceRoundingValidation = checkPriceRoundingValidation();
    const isValidNameOfGroup = await checkNameOfGroup();
    // check alerts from back
    const isAlertsErrors = checkAlertsErrors();

    if (
      isValid &&
      isValidPriseLimits &&
      exceptionsValidation &&
      priceRoundingValidation &&
      isValidNameOfGroup &&
      isAlertsErrors
    ) {
      if (isActiveStep(CurrentStep.SUMMARY)) {
        setValidationError({
          [CurrentStep.SUMMARY]: null,
        });
      }

      if (isValidNameOfGroup) {
        alert("DONE");

        //
        //request
      }
    }
  };

  const renderNextButton = () => {
    const currentStepLocal = STEP_KEYS[isHighlighted];

    if (currentStepLocal === CurrentStep.SUMMARY) {
      return (
        <ButtonStyled
          type="primary"
          text="Complete"
          htmlType="button"
          fill="gray-primary-900"
          className={`groups-empty__btn`}
          onClick={() => handleClickComplete()}
        />
      );
    }

    return (
      <ButtonStyled
        type="primary"
        text="Next"
        htmlType="button"
        fill="gray-primary-900"
        className={`groups-empty__btn ${nextDisabledBtnClassName}`}
        icon={<ArrowRight />}
        onClick={onNextStep}
        iconPosition="end"
      />
    );
  };

  const nextDisabledBtnClassName = isDisabledNextBtn() ? "disabled" : "";
  const prevDisabledBtnClassName = isHighlighted === 0 ? "disabled" : "";

  return (
    <div className="stepper__wrapper">
      {
        <GroupsOnCancelNotificatioPopup
          flag={isCancelConfirmationShow}
          onClose={() => setCancelConfirmationShow(false)}
          onOk={cancelApprove}
        />
      }
      <div className="stepper__container">
        <div className="stepper__aside">
          <Steps
            items={steps}
            onChange={onChange}
            current={activeStep}
            direction="vertical"
          />
        </div>
        <div className="children__content">{renderChildren()}</div>
      </div>
      <div className="stepper__actions">
        <div className="action__left">
          <ButtonStyled text="Cancel" htmlType="button" onClick={onCancel} />
        </div>

        <div className="action__left">
          <ButtonStyled
            text="Previous"
            htmlType="button"
            icon={<Arrow />}
            className={prevDisabledBtnClassName}
            onClick={onPreviousStep}
          />

          {renderNextButton()}
        </div>
      </div>
    </div>
  );
};

export default LayoutWithStepper;
