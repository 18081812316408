import "./groupsCreateForm.scss";
import { useState } from "react";
import { IFormGroup } from "@shared/interfaces";
import { useForm } from "@shared/model";
import { ButtonStyled } from "@shared/ui/components";
import onGroupFormValid from "@widgets/admin/groups/model/groupValidationHook";
import { Input, Typography } from "antd";
import { errorHandler } from "@shared/utils";
import { Close } from "@shared/ui/assets";
import { useGroupsStore } from "@entities/groups";

const { Title } = Typography;

const group: IFormGroup = {
  name: "",
};

interface IProps {
  onClose: (flag: boolean) => void;
}

const GroupsCreateForm = ({ onClose }: IProps) => {
  const [activeFormError, setActiveFormError] = useState<
    Record<string, string>
  >({});

  const { createGroups } = useGroupsStore((state) => state);

  const { formData, onSubmitForm, onChangeInput, resetFormData } =
    useForm<IFormGroup>(group, handlerBeforeSubmitForm);

  async function handlerBeforeSubmitForm() {
    const formErrors = onGroupFormValid(formData);
    const arrayOfErrors = Object.keys(formErrors);

    if (arrayOfErrors.length) {
      return setActiveFormError(formErrors);
    }

    setActiveFormError({});

    try {
      await createGroups();
      closePopUp();
    } catch (e) {
      const customError: any = errorHandler.isResponseHandled(e);
      console.log("Handler", e);
      console.log("CustomHAndler", customError);
    }
  }

  const closePopUp = () => {
    resetFormData();
    setActiveFormError({});
    onClose(false);
  };

  return (
    <form onSubmit={onSubmitForm} className="loginform__wrapper">
      <div onClick={closePopUp} className="login-close__icon">
        <Close />
      </div>
      <div className="form__item form-item__mb24">
        <label className="" htmlFor={"groupName"}>
          Group name
        </label>

        <Input
          id="groupName"
          className="gray-primary-900"
          name="name"
          type="text"
          value={formData.name}
          onChange={onChangeInput}
          status={activeFormError.name && "error"}
        />
        {activeFormError.name && (
          <Title type="danger" level={5}>
            {activeFormError.name}
          </Title>
        )}

        <Title level={5} className="form__promt">
          You can change group name later
        </Title>
      </div>

      <div className="df jc-fe">
        <ButtonStyled
          text="Cancel"
          htmlType="button"
          className="w-84 mr-16"
          onClick={closePopUp}
        />

        <ButtonStyled
          text="Create"
          type="primary"
          fill="gray-primary-900"
          htmlType="submit"
          className="w-84"
        />
      </div>
    </form>
  );
};

export default GroupsCreateForm;
