import { LayoutWithAside } from "@widgets/admin";
import { GroupsEditTitle, LayoutWithStepper } from "@widgets/admin/groups";

const GroupPage = () => {
  return (
      <LayoutWithAside>
        <>
          <h1>This is Page for one Group. It will be ready in different sprint</h1>
        </>
      </LayoutWithAside>
    )
}

export default GroupPage;