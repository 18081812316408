import type { MenuProps } from "antd";
import "./asideDropdown.scss";
import { Button, Dropdown, Space } from "antd";
import { FC } from "react";

interface IProps {
  children: React.ReactElement;
  items: MenuProps["items"];
  collapsed?: boolean;
  className?: string;
  placement?:
    | "bottomLeft"
    | "topLeft"
    | "topCenter"
    | "topRight"
    | "bottomCenter"
    | "bottomRight"
    | "top"
    | "bottom"
    | undefined;
}

const AsideDropdown: FC<IProps> = ({
  children,
  items,
  collapsed,
  className,
  placement = "bottomLeft",
}: IProps) => {
  return (
    <Space direction="vertical">
      <Space wrap>
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement={placement}
          overlayClassName={`aside-dropdown 
            ${className ? className : ""}
            ${collapsed ? "aside-dropdown__collapsed" : ""}
          `}
        >
          {children}
        </Dropdown>
      </Space>
    </Space>
  );
};

export default AsideDropdown;
