import { IProduct } from "@entities/products";
import { ArrowBorder, Delete } from "@shared/ui/assets";
import { Select } from "antd";
import { FC, useEffect, useState } from "react";
import "./itemException.scss";
import {
  CurrentStep,
  IProductException,
  LimitMSRP,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";
import { InputWithIcons, SwitcherCustom } from "@shared/ui/components";
import { cutStringToLimit } from "@shared/utils";

interface IProps {
  product: IProductException;
}

const optionsExceptionSelect = [
  {
    label: "Current price",
    value: SelectLimitPricing.CURRENT_PRICE,
  },
  {
    label: "Margin",
    value: SelectLimitPricing.MARGIN,
  },
  {
    label: "MSRP",
    value: SelectLimitPricing.MSRP,
  },
];

const msrpSwitchOptions = [
  { key: LimitMSRP.PLUS, content: <>+</> },
  { key: LimitMSRP.MINUS, content: <>-</> },
];

const ItemException: FC<IProps> = ({ product }) => {
  const [activeOption, setActiveOption] = useState(
    product?.exceptionLimits?.pricing || ""
  );

  const {
    removeExceptionById,
    getExceptions,
    editException,
    saveExceptionChange,
    validationErrors,
    setValidationError,
  } = useGroupsStore((state) => state);

  const handleChangeExceptionsSelect = (el: SelectLimitPricing) => {
    editException(product.id, { pricing: el }, "select");
    setActiveOption(el);
  };

  const setActiveClassName = () => {
    switch (activeOption) {
      case SelectLimitPricing.MARGIN:
        return "groups-price-limits__margin-select";
      case SelectLimitPricing.CURRENT_PRICE:
        return "groups-price-limits__current-price-select";
      case SelectLimitPricing.MSRP:
        return "";
    }
  };
  const msrpClassMinus = () => {
    if (activeOption === SelectLimitPricing.MSRP) {
      return "msrp-active-minus";
    }
    return "";
  };

  const indexMax = msrpSwitchOptions.findIndex(
    (el) => el.key === product?.exceptionLimits?.max?.metric
  );

  const indexMin = msrpSwitchOptions.findIndex(
    (el) => el.key === product?.exceptionLimits?.min?.metric
  );

  const setActiveModeToChangeRender = () => {
    switch (activeOption) {
      case SelectLimitPricing.MARGIN:
        return (
          <>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Min margin (optional)</div>
              <div className="df jc-c poz-r">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("min", value)
                  }
                  rightIcon={<>%</>}
                  classNameInput={`margin-input-exception ml-8 `}
                  value={product?.exceptionLimits?.min?.value || ""}
                />
                {isErrorInput() && (
                  <div className=" error-validation-message-exception">
                    At least one option should be specified
                  </div>
                )}
              </div>
            </div>
            <div className="groups-price-limits__choose-block-minus">
              <div className="title">&nbsp;</div>
              <div>-</div>
            </div>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Max margin (optional) </div>
              <div className="df jc-c">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("max", value)
                  }
                  rightIcon={<>%</>}
                  classNameInput="margin-input-exception ml-8"
                  value={product?.exceptionLimits?.max?.value || ""}
                />
              </div>
            </div>
          </>
        );
      case SelectLimitPricing.CURRENT_PRICE:
        return (
          <>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Min price (optional) </div>
              <div className="df poz-r">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("min", value)
                  }
                  rightIcon={<>$</>}
                  classNameInput="currentPrice-input-exception"
                  value={product?.exceptionLimits?.min?.value || ""}
                />
                {isErrorInput() && (
                  <div className=" error-validation-message-exception">
                    At least one option should be specified
                  </div>
                )}
              </div>
            </div>
            <div className="groups-price-limits__choose-block-minus">
              <div className="title">&nbsp;</div>
              <div>-</div>
            </div>
            <div className="groups-price-limits__choose-block-option">
              <div className="title ta-center">Max price (optional)</div>
              <div className="df">
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("max", value)
                  }
                  rightIcon={<>$</>}
                  classNameInput="currentPrice-input-exception"
                  value={product?.exceptionLimits?.max?.value || ""}
                />
              </div>
            </div>
          </>
        );
      case SelectLimitPricing.MSRP:
        return (
          <>
            <div className="groups-price-limits__choose-block-option">
              <div className="title df jc-se ">
                <div></div>
                <div className="ml-40">Min price (optional)</div>
              </div>
              <div className="df poz-r">
                <SwitcherCustom
                  activeIndex={indexMin >= 0 ? indexMin : 0}
                  data={msrpSwitchOptions}
                  onClick={(value) => handleChangeSwitch("min", value)}
                />
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("min", value)
                  }
                  rightIcon={<>%</>}
                  classNameInput="msrp-input-exception ml-8"
                  value={product?.exceptionLimits?.min?.value || ""}
                />
                {isErrorInput() && (
                  <div className=" error-validation-message-exception-msrp">
                    At least one option should be specified
                  </div>
                )}
              </div>
            </div>
            <div
              className={`groups-price-limits__choose-block-minus ${msrpClassMinus()}`}
            >
              <div className="title">&nbsp;</div>
              <div>-</div>
            </div>
            <div className="groups-price-limits__choose-block-option">
              <div className="title df jc-se ">
                <div></div>
                <div className="ml-40">Max price (optional)</div>
              </div>
              <div className="df jc-se">
                <SwitcherCustom
                  activeIndex={indexMax >= 0 ? indexMax : 0}
                  data={msrpSwitchOptions}
                  onClick={(value) => handleChangeSwitch("max", value)}
                />
                <InputWithIcons
                  wrapperClassName={`${isErrorClassInput()}`}
                  onChange={(value: string) =>
                    handleChangeExceptionsInput("max", value)
                  }
                  rightIcon={<>%</>}
                  classNameInput="msrp-input-exception ml-8"
                  value={product?.exceptionLimits?.max?.value || ""}
                />
              </div>
            </div>
          </>
        );
    }
  };

  const handleChangeSwitch = (key: string, value: string) => {
    editException(product.id, {
      [key]: { ...product?.exceptionLimits[key], metric: value },
    });
  };

  const msrpClass = () => {
    if (activeOption === SelectLimitPricing.MSRP) {
      return "msrp-active";
    }
    return "";
  };

  const handleRemoveEvent = async () => {
    await removeExceptionById(product.id);
    await getExceptions();

    const currentValidationErrors =
      useGroupsStore.getState().validationErrors[CurrentStep.PRICE_LIMITS];
    const copyExceptionProducts = currentValidationErrors?.exceptionProducts;
    if (copyExceptionProducts) {
      delete copyExceptionProducts[product.id];
      if (!copyExceptionProducts.length) {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            exceptionProducts: null,
          },
        });
      } else {
        setValidationError({
          [CurrentStep.PRICE_LIMITS]: {
            exceptionProducts: copyExceptionProducts,
          },
        });
      }
    }
  };

  const handleChangeExceptionsInput = (key: string, value: string) => {
    editException(product.id, {
      [key]: { ...product?.exceptionLimits[key], value },
    });
  };

  useEffect(() => {
    if (product.exceptionLimits) {
      saveExceptionChange(product.id);
    }
  }, [product.exceptionLimits]);

  const isErrorClassSelect = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.pricing) {
      return "error-validation-select";
    }

    return "";
  };

  const isErrorClassInput = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.rangeValidation) {
      return "error-validation-input";
    }
    return "";
  };

  const isErrorInput = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.rangeValidation) {
      return true;
    }

    return false;
  };
  const isErrorSelect = () => {
    const exceptionProducts =
      validationErrors[CurrentStep.PRICE_LIMITS]?.exceptionProducts;
    if (exceptionProducts && exceptionProducts[product.id]?.pricing) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <div className="groups-price-limits__product-for-exception">
        <div className="trash-icon" onClick={handleRemoveEvent}>
          <Delete color="#6C757D" />
        </div>
        <div className="groups-price-limits__product">
          <div className="groups-price-limits__product-info">
            <div>
              {product.imageUrl && (
                <img
                  alt="img-url"
                  className="product__img"
                  src={product.imageUrl}
                />
              )}
            </div>
            <div className="groups-price-limits__product-name">
              <span className="groups-price-limits__article">
                {product.article}
              </span>
              <span className="groups-price-limits__name">
                {cutStringToLimit(product.name)}
              </span>
            </div>
          </div>

          <div className="groups-price-limits__product-actions ">
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">Cost</div>
              <div className="groups-price-limits__product-item-value">
                $75.00
              </div>
            </div>
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">
                Current Price
              </div>
              <div className="groups-price-limits__product-item-value">
                $99.99
              </div>
            </div>
            <div className="groups-price-limits__product-actions-item">
              <div className="groups-price-limits__product-item">
                Current Margin
              </div>
              <div className="groups-price-limits__product-item-value">
                25.00%
              </div>
            </div>
          </div>
        </div>
        <div className="groups-price-limits__choose-block">
          <div
            className={`groups-price-limits__choose-block-limit ${msrpClass()}`}
          >
            <div className="title">Limit by</div>
            <div
              className={`select-limit-block-single ${setActiveClassName()}`}
            >
              <Select
                onChange={(el: SelectLimitPricing) =>
                  handleChangeExceptionsSelect(el)
                }
                value={activeOption || null}
                options={optionsExceptionSelect}
                suffixIcon={<ArrowBorder color="#212527" />}
                placeholder={"Select limit"}
                defaultValue={null}
                className={`custom-arrow  ${isErrorClassSelect()}`}
              />
              {isErrorSelect() && (
                <div className="error-validation-message mr-16 ">
                  Please select a limit
                </div>
              )}
            </div>
          </div>
          <>{setActiveModeToChangeRender()}</>
        </div>
      </div>
    </div>
  );
};

export default ItemException;
