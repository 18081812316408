import "./elasticity.scss";
import chart from "../../images/chart.svg";
import { Select } from "antd";
import { ArrowBorder, Error, Info } from "@shared/ui/assets";
import { AlertCustom } from "@shared/ui/components";
import {
  AlertType,
  CurrentStep,
  MetricToGrow,
  MetricToMaintain,
  useGroupsStore,
} from "@entities/groups";

const dataToGow = {
  title: "Select metric to grow",
  subtitle: "This is the primary metric to maximize",
  selectOptions: [
    {
      label: "Sales",
      value: MetricToGrow.SALES,
    },
    {
      label: "Profit",
      value: MetricToGrow.PROFIT,
      activeDefault: true,
    },
    {
      label: "Revenue",
      value: MetricToGrow.REVENUE,
    },
  ],
};

const dataToMaintain = {
  title: "Select metric to maintain (optional)",
  subtitle:
    "This metric will be maintained while optimizing the primary metric",
  selectOptions: [
    {
      label: "None",
      value: MetricToMaintain.NONE,
    },
    {
      label: "Sales",
      value: MetricToMaintain.SALES,
      activeDefault: true,
    },
    {
      label: "Profit",
      value: MetricToMaintain.PROFIT,
    },
    {
      label: "Revenue",
      value: MetricToMaintain.REVENUE,
    },
  ],
};
const alertTextError =
  "Settings reset due to Strategy Type changes. Please review and reconfigure the Strategy Rule.";

const Elasticity = () => {
  const { metricToGrow, metricToMaintain } = useGroupsStore(
    (state) => state.strategy.strategyRule.pricesFormula
  );
  const { strategy } = useGroupsStore((state) => state);
  const alert = strategy?.strategyRule?.alerts[0];
  const handleChange = (key: string, value: string) => {
    useGroupsStore.setState((state) => ({
      strategy: {
        ...state.strategy,
        strategyRule: {
          ...state.strategy.strategyRule,
          alerts: [],
          pricesFormula: {
            ...state.strategy.strategyRule.pricesFormula,
            [key]: value,
          },
        },
      },
      keyOfStrategy: CurrentStep.STRATEGY_RULE,
    }));
  };

  const itemToShowMetricToGrow = dataToGow.selectOptions.find(
    (el) => el.value === metricToGrow
  )?.label;
  const itemToShowMetricToMaintain = dataToMaintain.selectOptions.find(
    (el) => el.value === metricToMaintain
  )?.label;

  const alertElements = [
    {
      title: `The system will analyze historical sales data to see how price changes affect ${itemToShowMetricToGrow} and ${itemToShowMetricToMaintain}.`,
    },
    {
      title: `It will then suggest a “sweet spot” - price that makes more ${itemToShowMetricToGrow} without compromising ${itemToShowMetricToMaintain}⁠.`,
    },
    {
      title:
        "The system will consider various factors like customer behavior, seasonality, and market trends to make these recommendations⁠.",
    },
  ];

  const alertMessage = (
    <div>
      The strategy will function as follows:
      <ul className="groups-strategy-rule__list">
        {alertElements.map((el, index) => {
          return <li key={index}>{el.title}</li>;
        })}
      </ul>
    </div>
  );

  return (
    <div className="groups-strategy-rule__wrapper-elasticity">
      <h3 className="groups-strategy-rule__title-elasticity ">Strategy Rule</h3>
      <h5 className="groups-strategy-rule__subtitle-elasticity">
        This is the key strategy, and other settings will support it
      </h5>

      {alert?.type === AlertType.ERROR ? (
        <AlertCustom
          type="error"
          message={alertTextError}
          icon={<Error />}
          className="groups-strategy-rule__alert-error"
          closable={false}
        />
      ) : null}
      <div className="groups-strategy-rule__content-elasticity">
        <div className="groups-strategy-rule__content-block">
          <div className="groups-strategy-rule__content-item">
            <div className="groups-strategy-rule__content-item-block">
              <h5 className="groups-strategy-rule__text-elasticity">
                {dataToGow.title}
              </h5>
              <div className="groups-strategy-rule__subtitle-text">
                {dataToGow.subtitle}
              </div>
              <div>
                <Select
                  onChange={(value) => handleChange("metricToGrow", value)}
                  suffixIcon={<ArrowBorder />}
                  value={itemToShowMetricToGrow || null}
                  defaultValue={""}
                  optionFilterProp="label"
                  options={dataToGow.selectOptions.map((option) => ({
                    ...option,
                    disabled: option.label === itemToShowMetricToMaintain,
                  }))}
                  className={`gray-primary-900 groups-strategy-rule-select`}
                  placeholder={"Select metric"}
                />
              </div>
            </div>
            <div className="groups-strategy-rule__content-item-block">
              <h5 className="groups-strategy-rule__text-elasticity">
                {dataToMaintain.title}
              </h5>
              <div className="groups-strategy-rule__subtitle-text">
                {dataToMaintain.subtitle}
              </div>
              <div>
                <Select
                  onChange={(value) => {
                    handleChange("metricToMaintain", value);
                  }}
                  suffixIcon={<ArrowBorder />}
                  value={itemToShowMetricToMaintain || null}
                  defaultValue={"Select metric"}
                  optionFilterProp="label"
                  options={dataToMaintain.selectOptions.map((option) => ({
                    ...option,
                    disabled: option.label === itemToShowMetricToGrow,
                  }))}
                  className={`gray-primary-900 groups-strategy-rule-select`}
                  placeholder={"Select metric"}
                />
              </div>
            </div>
          </div>

          <div className="groups-strategy-rule__content-item">
            <img
              src={chart}
              alt="Chart"
              className="groups-strategy-rule__chart"
            />
          </div>
        </div>
        <div>
          <AlertCustom message={alertMessage} icon={<Info />} type="default" />
        </div>
      </div>
    </div>
  );
};

export default Elasticity;
