import {
  AlertCustom,
  CheckboxStyled,
  InputWithIcons,
  SwitcherCustom,
} from "@shared/ui/components";
import "./priceRounding.scss";
import {
  CurrentStep,
  PriceRoundingEnum,
  useGroupsStore,
} from "@entities/groups";
import { Info } from "@shared/ui/assets";
import { adjustPriceValue } from "@shared/utils";

const checkBoxesArr = [
  {
    text: "Round new price per decimals",
    key: "decimal",
    iconInput: <>¢</>,
    classInputWrapper: "ml-24 w-84",
    classNameInput: "input-price-rounding-w",
    validationPlaceholderClass:
      "groups-price-rounding__placeholder-error-decimal",
    placeholderText: "0-99",
  },
  {
    text: "Round new price per integer",
    key: "integer",
    iconInput: <>$</>,
    classInputWrapper: "ml-36 w-84",
    classNameInput: "input-price-rounding-w",
    validationPlaceholderClass:
      "groups-price-rounding__placeholder-error-integer",
    placeholderText: "0-9",
  },
];

const dataSwitch = [
  {
    key: PriceRoundingEnum.ROUND_DOWN,
    content: <>Round down</>,
  },
  {
    key: PriceRoundingEnum.ROUND_UP,
    content: <>Round up</>,
  },
];

const PriceRounding = () => {
  const priceRounding = useGroupsStore((state) => state.strategy.priceRounding);
  const { validationErrors, setValidationError } = useGroupsStore(
    (state) => state
  );

  const isActiveCheckbox = (key: string) => {
    return priceRounding[key]?.active || false;
  };

  const handleCheck = (key: string) => {
    let isCheck = false;
    useGroupsStore.setState(() => {
      isCheck =
        !useGroupsStore.getState().strategy[CurrentStep.PRICE_ROUNDING][key]
          ?.active;
      return {
        ...useGroupsStore.getState(),
        strategy: {
          ...useGroupsStore.getState().strategy,
          [CurrentStep.PRICE_ROUNDING]: {
            ...useGroupsStore.getState().strategy[CurrentStep.PRICE_ROUNDING],
            [key]: {
              ...useGroupsStore.getState().strategy[CurrentStep.PRICE_ROUNDING][
                key
              ],
              active:
                !useGroupsStore.getState().strategy[CurrentStep.PRICE_ROUNDING][
                  key
                ]?.active,
              value: "",
            },
          },
        },
        keyOfStrategy: CurrentStep.PRICE_ROUNDING,
      };
    });

    if (!isCheck) {
      setValidationError({
        [CurrentStep.PRICE_ROUNDING]: {
          ...useGroupsStore.getState().validationErrors[
            CurrentStep.PRICE_ROUNDING
          ],
          [key]: null,
        },
      });
    }
  };

  const handleChangeSwitch = (value: PriceRoundingEnum) => {
    useGroupsStore.setState({
      ...useGroupsStore.getState(),
      strategy: {
        ...useGroupsStore.getState().strategy,
        [CurrentStep.PRICE_ROUNDING]: {
          ...useGroupsStore.getState().strategy[CurrentStep.PRICE_ROUNDING],
          metric: value,
        },
      },
      keyOfStrategy: CurrentStep.PRICE_ROUNDING,
    });
  };

  const handleChangeInput = (key: string, value: string) => {
    let isValid = false;
    if (key === "decimal") {
      isValid = /^(?:[1-9]?[0-9]|99)?$/.test(value);
    }
    if (key === "integer") {
      isValid = /^(?:[0-9]?)?$/.test(value);
    }

    if (!isValid) {
      return;
    }
    useGroupsStore.setState({
      ...useGroupsStore.getState(),
      strategy: {
        ...useGroupsStore.getState().strategy,
        [CurrentStep.PRICE_ROUNDING]: {
          ...useGroupsStore.getState().strategy[CurrentStep.PRICE_ROUNDING],
          [key]: {
            ...useGroupsStore.getState().strategy[CurrentStep.PRICE_ROUNDING][
              key
            ],
            value,
          },
        },
      },
      keyOfStrategy: CurrentStep.PRICE_ROUNDING,
    });
  };

  const checkIndexActiveSwitch = () => {
    return dataSwitch.findIndex(
      (el: { key: PriceRoundingEnum }) => el.key === priceRounding.metric
    ) >= 0
      ? dataSwitch.findIndex(
          (el: { key: PriceRoundingEnum }) => el.key === priceRounding.metric
        )
      : 0;
  };

  const decimalValidError =
    validationErrors[CurrentStep.PRICE_ROUNDING]?.decimal;
  const integerValidError =
    validationErrors[CurrentStep.PRICE_ROUNDING]?.integer;

  const isErrorClassInput = (key: string) => {
    if (key === "decimal" && decimalValidError) {
      return "error-validation-input";
    }
    if (key === "integer" && integerValidError) {
      return "error-validation-input";
    }
    return "";
  };

  const chooseErrorTextInput = (key: string) => {
    if (key === "decimal" && decimalValidError) {
      return (
        <span className="error-validation-message">
          Please enter a value between 0-99
        </span>
      );
    }
    if (key === "integer" && integerValidError) {
      return (
        <span className="error-validation-message">
          Please enter a value between 0-9
        </span>
      );
    }
    return "";
  };

  return (
    <div className="groups-price-rounding__wrapper">
      <h3 className="groups-price-rounding__title">Price Roundings</h3>
      <h5 className="groups-price-rounding__subtitle">
        Use price roundings to create a more appealing prices for your customers
      </h5>
      <div className="groups-price-rounding-content">
        <div>
          <h4 className="groups-price-rounding__text">
            Set roundings for all items
          </h4>
          <div>
            {checkBoxesArr.map(
              (
                {
                  text,
                  key,
                  iconInput,
                  classInputWrapper,
                  classNameInput,
                  validationPlaceholderClass,
                  placeholderText,
                },
                ind
              ) => {
                return (
                  <div
                    className="df ai-center mt-24 groups-price-rounding-block"
                    key={ind}
                  >
                    <div>
                      <CheckboxStyled
                        checked={priceRounding[key]?.active}
                        text={text}
                        onChange={() => handleCheck(key)}
                        key={"text"}
                      />
                    </div>
                    {isActiveCheckbox(key) ? (
                      <div className="poz-r">
                        <InputWithIcons
                          value={priceRounding[key]?.value || ""}
                          rightIcon={iconInput}
                          wrapperClassName={`${classInputWrapper} ${isErrorClassInput(
                            key
                          )}`}
                          classNameInput={`${classNameInput}`}
                          onChange={(value: string) =>
                            handleChangeInput(key, value)
                          }
                        />
                        <span className={validationPlaceholderClass}>
                          {chooseErrorTextInput(key) || placeholderText}
                        </span>
                      </div>
                    ) : null}
                  </div>
                );
              }
            )}
          </div>
          <div className="groups-price-rounding-direction df">
            <div className="df ai-center">Rounding direction</div>
            <div className="ml-16">
              <SwitcherCustom
                activeIndex={checkIndexActiveSwitch()}
                data={dataSwitch}
                onClick={(value) =>
                  handleChangeSwitch(value as PriceRoundingEnum)
                }
              />
            </div>
          </div>
          <div>
            <AlertCustom
              className="rounding-alert-block"
              type="default"
              message={
                <>
                  <div className="rounding-alert">
                    Rounding preview based on your configuration
                  </div>
                  <div>
                    <span className="rounding-alert-example-italic">{`Example: $123.43 -> `}</span>
                    <span className="rounding-alert-example-number">{`$${adjustPriceValue(
                      {
                        decimalMain: +priceRounding["decimal"]?.value,
                        integerMain: +priceRounding["integer"]?.value,
                        round: dataSwitch[checkIndexActiveSwitch()].key,
                        baseNum: 123.43,
                      }
                    )}`}</span>
                  </div>
                </>
              }
              icon={<Info width={20} height={20} />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceRounding;
