import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import { create } from "zustand";
import { IDataTransfer, IQuery } from "@shared/interfaces";
import {
  AlertType,
  AutoRepricingEnum,
  CurrentStep,
  EGroupStatus,
  EGroupType,
  ICompetitor,
  IGroup,
  IGroupFormEdit,
  IGroupState,
  IStrategyModel,
  IValidationKey,
  LimitMSRP,
  Metric,
  PriceRoundingEnum,
  Priority,
  Rate,
  StrategyKeys,
  StrategyTypeEnum,
} from "./groups.types";
import groupsService from "../api";
import { GroupDTO } from "./group.dto";
import { errorHandler } from "@shared/utils";

const initStrategy: IStrategyModel = {
  id: "",
  type: null,
  currentStep: CurrentStep.PRODUCT_ITEM,
  strategyRule: {
    alerts: [],
    competitors: [],
    pricesFormula: {
      type: null,
      value: "",
      metric: Metric.PLUS,
      rate: Rate.DOLLAR,
      metricToGrow: "",
      metricToMaintain: "",
    },
  },
  priceLimits: {
    alerts: [],
    min: {
      active: true,
      pricing: null,
      pricingValue: 0,
      metric: LimitMSRP.PLUS,
    },
    max: {
      active: false,
      pricing: null,
      pricingValue: 0,
      metric: LimitMSRP.PLUS,
    },
  },
  exceptionProducts: [],
  priceRounding: {
    metric: PriceRoundingEnum.ROUND_DOWN,
    decimal: {
      active: false,
      value: "",
    },
    integer: {
      active: false,
      value: "",
    },
  },

  updatePolicy: {
    value: AutoRepricingEnum.AUTO_REPRICING_OFF,
  },
};

export const initStateGroup: IGroup = {
  id: "",
  name: "",
  totalItems: 0,
  repricedItems: 0,
  isSelected: false,
  type: EGroupType.DRAFT,
  status: EGroupStatus.COMING_SOON,
};

export const useGroupsStore = create<IGroupState>()(
  devtools(
    immer((set, get) => ({
      brands: [],
      groups: [],
      filters: {},
      categories: [],
      recordsCount: 0,
      subcategories: [],
      configId: undefined,
      newPricesAvailable: [],
      lastPricesUpdate: undefined,
      currentGroup: initStateGroup,
      isUpdatePriceInProcess: false,
      strategy: initStrategy,
      keyOfStrategy: null,
      competitorsAll: [],
      competitorsInGroup: [],
      competitorsAllSelectedIds: [],
      competitorsInGroupSelectedIds: [],
      productsInGroup: [],
      productsInGroupSelectedIds: [],
      recordsCountInGroup: 0,
      productInGroupIdRadioSelected: "",
      exceptionProducts: [],
      validationErrors: {},
      allExceptions: [],

      getConfig: async () => {
        try {
          const { data } = await groupsService.getConfig();

          set({
            lastPricesUpdate: data.lastGroupsPricesUpdated,
            configId: data.id,
          });
        } catch (e) {
          console.log(e);
        }
      },
      getGroups: async (queryParams: IQuery) => {
        if (queryParams?.filterBy?.toUpperCase() === "ALL") {
          queryParams.filterBy = "";
        }

        try {
          const { data } = await groupsService.getGroups(queryParams);
          set({
            groups: data.groups,
            recordsCount: data.count,
            newPricesAvailable: data.groupsReadyForRepricing,
          });
        } catch (e) {
          console.log(e);
        }
      },
      setGroupName: (name: string) => {
        set({ currentGroup: { ...get().currentGroup, name } });
      },
      getGroupsFilters: async (queryParams: IQuery) => {
        try {
          const { data } = await groupsService.getGroupsFilters(queryParams);

          set({ filters: data });
        } catch (e) {
          console.log(e);
        }
      },
      getGroup: async (groupId: string) => {
        try {
          const { data } = await groupsService.getGroup(groupId);
          const { strategies, ...groupInfo } = data;

          set({
            strategy: strategies[0],
            currentGroup: groupInfo,
          });
        } catch (e) {
          console.log(e);
        }
      },
      getCategories: async () => {
        try {
          const { data } = await groupsService.getCategories();
          set({ categories: data });
        } catch (e) {
          console.log(e);
        }
      },
      getSubCategories: async () => {
        try {
          const { data } = await groupsService.getSubCategories();
          set({ subcategories: data });
        } catch (e) {
          console.log(e);
        }
      },
      getBrands: async () => {
        try {
          const { data } = await groupsService.getBrands();
          set({ brands: data });
        } catch (e) {
          console.log(e);
        }
      },
      createGroups: async () => {
        const recordsCount = get().recordsCount;

        const createGroupDto = new GroupDTO({
          name: `Draft Group ${recordsCount + 1}`,
        }).create();
        try {
          const { data }: { data: IGroup } = await groupsService.creteGroup(
            createGroupDto
          );
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      updateGroup: async (formData: IGroupFormEdit) => {
        const groupId = formData.id;
        const name = formData.name;
        try {
          await groupsService.updateGroup({
            id: groupId,
            name: name,
          });

          const groups = get().groups;

          const updatedGroups = groups.map((group) => {
            if (group.id === groupId) {
              group.name = name;
            }

            return group;
          });

          set({
            groups: updatedGroups,
            currentGroup: initStateGroup,
          });
        } catch (e) {
          console.log(e);
        }
      },
      updateGroupName: async (value: string) => {
        try {
          const { data } = await groupsService.updateGroup({
            id: get().currentGroup.id,
            name: value,
          });
          if (data?.status) {
            throw data;
          }
          return data;
        } catch (e) {
          const customError: any = errorHandler.isResponseHandled(e);

          if (customError) {
            throw customError;
          }
        }
      },
      updatePrices: async () => {
        const configId = get().configId;

        if (!configId) {
          return;
        }

        set({ isUpdatePriceInProcess: true });

        try {
          const { data } = await groupsService.updateGroupPrices(configId);

          set({
            lastPricesUpdate: data.lastGroupsPricesUpdated,
            isUpdatePriceInProcess: false,
          });
        } catch (e) {
          console.log(e);
        }
      },
      deleteGroup: async (group: IGroup, queryParams: IQuery) => {
        try {
          await groupsService.deleteGroup(group.id);

          await get().getGroupsFilters(queryParams);
          await get().getGroups(queryParams);
        } catch (e) {
          console.log(e);
        }
      },
      removeGroup: async (id: string) => {
        try {
          await groupsService.deleteGroup(id);
        } catch (e) {
          console.log(e);
        }
      },
      selectGroup: (groupId: string, flag: boolean) => {
        const groups = get().groups;

        const groupsWithSelected = groups.map((group) => {
          if (group.id === groupId) {
            group.isSelected = flag;
          }

          return group;
        });

        set({ groups: groupsWithSelected });
      },
      selectAllGroup: (flag: boolean) => {
        const groups = get().groups;

        const groupsWithSelected = groups.map((group) => {
          group.isSelected = flag;

          return group;
        });

        set({ groups: groupsWithSelected });
      },

      showUpdatePriceAlert: (arr: []) => {
        set({ newPricesAvailable: arr });
      },

      setCurrentGroup: (group: IGroup) => {
        set({ currentGroup: group });
      },
      setCurrentStep: (currentStep: CurrentStep) => {
        const strategy = { ...get().strategy };
        strategy.currentStep = currentStep;
        set({ strategy });
      },
      saveEmptyGroupWithProducts: async (
        selectedProductsIds: string[],
        groupId: string | null,
        selectedCategory: string
      ) => {
        const recordsCount = get().recordsCount;

        const groupDto = new GroupDTO({
          name: `Draft Group ${recordsCount + 1}`,
          selectedProductsIds: selectedProductsIds,
          selectedCategory: selectedCategory,
        }).create();

        try {
          const { data } = await groupsService.addProductToGroup(
            groupDto,
            groupId
          );
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      getAllCompetitors: async (groupId: string, queryParams: IQuery) => {
        const strategyId = get().strategy.id;

        try {
          const { data } = await groupsService.getAllCompetitors(
            groupId,
            queryParams,
            strategyId
          );

          set({ competitorsAll: data });
        } catch (e) {
          console.log(e);
        }
      },
      getCompetitorsInGroup: async (groupId: string, params: IDataTransfer) => {
        const strategyId = get().strategy.id;
        try {
          const { data } = await groupsService.getCompetitorsInGroup(
            groupId,
            strategyId,
            params
          );

          set({ competitorsInGroup: data });
          set({
            strategy: {
              ...get().strategy,
              strategyRule: {
                ...get().strategy.strategyRule,
                competitors: data.map((item: any) => item.id),
              },
            },
          });
        } catch (e) {
          console.log(e);
        }
      },
      selectCompetitorsAll: (flag: boolean) => {
        const competitorsAll = get().competitorsAll;
        const competitorsAllSelectedIds = get().competitorsAllSelectedIds;

        if (flag) {
          set({
            competitorsAllSelectedIds: Array.from(
              new Set([
                ...competitorsAllSelectedIds.concat(
                  competitorsAll.map((competitor) => competitor.id)
                ),
              ])
            ),
          });
        } else {
          set({
            competitorsAllSelectedIds: competitorsAllSelectedIds.filter(
              (id) => !competitorsAll.find((product) => product.id === id)
            ),
          });
        }
      },
      selectCompetitorsInGroup: (flag: boolean) => {
        const competitorsInGroup = get().competitorsInGroup;
        const competitorsInGroupSelectedIds =
          get().competitorsInGroupSelectedIds;

        if (flag) {
          set({
            competitorsInGroupSelectedIds: Array.from(
              new Set([
                ...competitorsInGroupSelectedIds.concat(
                  competitorsInGroup.map((competitor) => competitor.id)
                ),
              ])
            ),
          });
        } else {
          set({
            competitorsInGroupSelectedIds: competitorsInGroupSelectedIds.filter(
              (id) =>
                !competitorsInGroup.find((competitor) => competitor.id === id)
            ),
          });
        }
      },
      selectCompetitor: (competitorId: string) => {
        const competitorsAllSelectedIds = get().competitorsAllSelectedIds;
        const indexOfSelectedProduct = competitorsAllSelectedIds.findIndex(
          (id) => id === competitorId
        );

        if (indexOfSelectedProduct !== -1) {
          const updatedProductsSelectedIds = [
            ...competitorsAllSelectedIds.slice(0, indexOfSelectedProduct),
            ...competitorsAllSelectedIds.slice(indexOfSelectedProduct + 1),
          ];
          set({ competitorsAllSelectedIds: updatedProductsSelectedIds });
        } else {
          set({
            competitorsAllSelectedIds: [
              ...competitorsAllSelectedIds,
              competitorId,
            ],
          });
        }
      },
      selectCompetitorInGroup: (competitorId: string) => {
        const competitorsInGroupSelectedIds =
          get().competitorsInGroupSelectedIds;
        const indexOfSelectedCompetitors =
          competitorsInGroupSelectedIds.findIndex((id) => id === competitorId);

        if (indexOfSelectedCompetitors !== -1) {
          const updatedProductsSelectedIds = [
            ...competitorsInGroupSelectedIds.slice(
              0,
              indexOfSelectedCompetitors
            ),
            ...competitorsInGroupSelectedIds.slice(
              indexOfSelectedCompetitors + 1
            ),
          ];
          set({ competitorsInGroupSelectedIds: updatedProductsSelectedIds });
        } else {
          set({
            competitorsInGroupSelectedIds: [
              ...competitorsInGroupSelectedIds,
              competitorId,
            ],
          });
        }
      },
      getMonthlyCategoryTraffic: async (groupId: string) => {
        try {
          const { data } = await groupsService.getMonthlyCategoryTraffic(
            groupId
          );
          return data;
        } catch (e) {
          console.log(e);
        }
      },
      updateStrategy: async () => {
        const strategyId = get().strategy.id;
        const strategy = get().strategy;
        const keyOfStrategy = get().keyOfStrategy;
        if (!keyOfStrategy) {
          return;
        }
        const dataRequest = {
          currentStep: strategy.currentStep,
          [keyOfStrategy]: strategy[keyOfStrategy],
        };
        if (!dataRequest[keyOfStrategy]) {
          return;
        }

        try {
          await groupsService.updateStrategy(strategyId, dataRequest);
        } catch (error) {
          console.log(error);
        }
      },
      setStrategyType: (type: StrategyTypeEnum, key: StrategyKeys) => {
        set({ strategy: { ...get().strategy, type } });
        set({ keyOfStrategy: key });
      },
      setKeyOfStrategy: (keyOfStrategy: StrategyKeys) => {
        set({ keyOfStrategy });
      },
      addCompetitorsToGroup: async () => {
        try {
          const { data } = await groupsService.addCompetitorsToGroup(
            get().competitorsAllSelectedIds,
            get().strategy.id,
            get().strategy.currentStep
          );

          return data;
        } catch (error) {
          console.log(error);
        }
      },
      setCompetitorsAllSelectedIds: () => {
        try {
          set({
            competitorsAllSelectedIds: [],
          });
        } catch (error) {
          console.log(error);
        }
      },
      setCompetitorsInGroup: (arr: ICompetitor[]) => {
        set({ competitorsInGroup: arr });
      },
      getProductsInGroup: async (params: IDataTransfer) => {
        try {
          const { data } = await groupsService.getProductsInGroup(params);
          set({
            productsInGroup: data.items,
            recordsCountInGroup: data.count,
          });
        } catch (e) {
          console.log(e);
        }
      },
      deleteProductsInGroup: async (groupId: number) => {
        try {
          const productsInGroupSelectedIds = get().productsInGroupSelectedIds;

          await groupsService.deleteProductsInGroup(
            groupId,
            productsInGroupSelectedIds
          );
          set({ productsInGroupSelectedIds: [] });
        } catch (e) {
          console.log(e);
        }
      },
      selectProductInGroup: (productId: string, flag: boolean) => {
        const productsInGroupSelectedIds = get().productsInGroupSelectedIds;
        const indexOfSelectedProduct = productsInGroupSelectedIds.findIndex(
          (id) => id === productId
        );

        if (indexOfSelectedProduct !== -1) {
          const updatedProductsSelectedIds = [
            ...productsInGroupSelectedIds.slice(0, indexOfSelectedProduct),
            ...productsInGroupSelectedIds.slice(indexOfSelectedProduct + 1),
          ];
          set({ productsInGroupSelectedIds: updatedProductsSelectedIds });
        } else {
          set({
            productsInGroupSelectedIds: [
              ...productsInGroupSelectedIds,
              productId,
            ],
          });
        }
      },
      selectAllProductsInGroup: (flag: boolean) => {
        const productsInGroup = get().productsInGroup;
        const productsInGroupSelectedIds = get().productsInGroupSelectedIds;

        if (flag) {
          set({
            productsInGroupSelectedIds: Array.from(
              new Set([
                ...productsInGroupSelectedIds.concat(
                  productsInGroup.map((product) => product.id)
                ),
              ])
            ),
          });
        } else {
          set({
            productsInGroupSelectedIds: productsInGroupSelectedIds.filter(
              (id) => !productsInGroup.find((product) => product.id === id)
            ),
          });
        }
      },
      removeSelectProductsIdsInGroup: () => {
        set({ productsInGroupSelectedIds: [] });
      },
      setProductsInGroup: (value: []) => {
        set({ productsInGroup: value });
      },
      removeProductsInGroup: async () => {
        await groupsService.removeProductsInGroup(get().currentGroup.id);
      },

      clearStrategyKey: (key: StrategyKeys) => {
        set({ strategy: { ...get().strategy, [key]: initStrategy[key] } });
      },
      addAlertToStore: (key: StrategyKeys, typeAlert: AlertType | "") => {
        const partOfStrategy = get().strategy[key] ?? {};
        set({
          strategy: {
            ...get().strategy,
            [key]: {
              ...partOfStrategy,
              alerts: [
                ...(partOfStrategy.alerts ?? []),
                { type: typeAlert, priority: Priority.DEFAULT },
              ],
            },
          },
        });
      },
      setProductInGroupIdRadioSelected: (value: string) => {
        set({
          productInGroupIdRadioSelected: value,
        });
      },
      addExceptionToGroup: async () => {
        const groupId = get().currentGroup.id;
        await groupsService.addExceptionToGroup(
          get().productInGroupIdRadioSelected,
          groupId
        );
      },
      getExceptions: async () => {
        const groupId = get().currentGroup.id;
        const { data } = await groupsService.getExceptions(groupId);
        set({ exceptionProducts: data });
      },
      removeExceptionById: async (id: string) => {
        await groupsService.removeExceptionById(id);
      },
      getAllExceptions: async (params: IDataTransfer) => {
        const { data } = await groupsService.getAllExceptions(params);
        set({ allExceptions: data });
        return data;
      },
      removeCompetitors: async () => {
        await groupsService.removeCompetitors(
          get().competitorsInGroupSelectedIds,
          get().strategy.id
        );
        set({
          competitorsInGroup: get().competitorsInGroup.filter(
            (el) => !get().competitorsInGroupSelectedIds.includes(el.id)
          ),
        });
        set({ competitorsInGroupSelectedIds: [] });
      },
      setValidationError: (error: {
        [key: keyof IStrategyModel]: IValidationKey | null;
      }) => {
        set({ validationErrors: { ...get().validationErrors, ...error } });
      },
      editException: (productId: string, obj: any, mode?: string) => {
        const exceptionProducts = get().exceptionProducts;
        const newExceptionProducts = exceptionProducts.map((product) =>
          product.id === productId
            ? {
                ...product,
                exceptionLimits: mode
                  ? { ...obj }
                  : { ...product.exceptionLimits, ...obj },
              }
            : product
        );
        set({ exceptionProducts: newExceptionProducts });
      },
      saveExceptionChange: async (productId: string) => {
        const exceptionLimits = get().exceptionProducts.find(
          (el) => el.id === productId
        )?.exceptionLimits;

        const dataTransfer: { [key: string]: any } = {};
        if (exceptionLimits?.min?.value) {
          dataTransfer.min = {
            ...exceptionLimits?.min,
            value: exceptionLimits.min,
          };
        }
        if (exceptionLimits?.max?.value) {
          dataTransfer.max = {
            ...exceptionLimits?.max,
            value: exceptionLimits.max,
          };
        }
        if (exceptionLimits) {
          await groupsService.saveExceptionChange(productId, exceptionLimits);
        }
      },
      clearAlertsToStore: async (key: StrategyKeys) => {
        set({
          strategy: {
            ...get().strategy,
            [key]: {
              ...get().strategy[key],
              alerts: [],
            },
          },
        });
      },
      clearStrategyInStore: () => {
        const copyInitStrategy = JSON.parse(JSON.stringify(initStrategy));
        set({ validationErrors: {} });
        set({ strategy: copyInitStrategy });
      },
    }))
  )
);
