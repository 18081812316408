import "./itemsPopup.scss";
import { FC, useEffect, useState } from "react";
import { Checkbox, Divider, Modal } from "antd";
import { ButtonStyled } from "@shared/ui/components";
import { useParams } from "react-router-dom";
import { Close } from "@shared/ui/assets";
import { generateTextColumn, Pagination } from "@shared/common";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import { IProduct, useProductsStore } from "@entities/products";
import { useShallow } from "zustand/react/shallow";
import {
  AlertType,
  CurrentStep,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";
import { ItemsPopupBulkAction } from "../../ItemsPopupBulkAction";
import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import {
  BRAND_PARAM,
  CATEGORY_PARAM,
  DEFAULT_LIMIT_PAGINATION,
  DEFAULT_OFFSET_PAGINATION,
  DEFAULT_OPTION_SELECT_STRING,
  DIRECTION_PARAM,
  GROUP_ID_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
  SUBCATEGORY_PARAM,
} from "@shared/constants";
import {
  cutStringToLimit,
  filteredCompareQueryObj,
  getItemWord,
} from "@shared/utils";
import { useDebounce } from "use-debounce";

interface IProps {
  flag: boolean;
  setItemsPopupShow: (bool: boolean) => void;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
  COST = "cost",
  BRAND = "brand",
  SUBCATEGORY = "subcategory",
  STORE = "store",
}

const ItemsPopup: FC<IProps> = ({ flag, setItemsPopupShow }: IProps) => {
  const {
    setProducts,
    setProductsSelectedIds,
    selectAllProducts,
    selectProduct,
  } = useProductsStore(useShallow((state) => state));
  const { saveEmptyGroupWithProducts } = useGroupsStore(
    useShallow((state) => state)
  );
  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const {
    strategy: { priceLimits, strategyRule },
  } = useGroupsStore((state) => state);
  const {
    addAlertToStore,
    currentGroup,
    strategy: { type },
  } = useGroupsStore((state) => state);
  const { pricesFormula } = strategyRule;
  const { id } = useParams();

  const products = useProductsStore((state) => state.products);
  const productsInGroup = useGroupsStore((state) => state.productsInGroup);
  const { allExceptions, getAllExceptions } = useGroupsStore((state) => state);
  const productsSelectedIds = useProductsStore(
    useShallow((state) => state.productsSelectedIds)
  );
  const recordsCount = useProductsStore(
    useShallow((state) => state.recordsCount)
  );
  const isAllSelected = products.every((product) =>
    productsSelectedIds.includes(product.id)
  );
  const [currentLimit, setCurrentLimit] = useState(DEFAULT_LIMIT_PAGINATION);
  const [currentOffset, setCurrentOffset] = useState(DEFAULT_OFFSET_PAGINATION);
  const { getProducts } = useProductsStore((state) => state);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | undefined;
  }>({
    key: "",
    direction: undefined,
  });

  const [searchValue, setSearchValue] = useState<string>("");
  const [currentCategory, setCurrentCategory] = useState<string>("");

  useEffect(() => {
    setCurrentCategory(currentGroup?.selectedCategory || "");
  }, [currentGroup?.selectedCategory]);

  const [currentBrand, setCurrentBrand] = useState<string>("");
  const [currentSubCategory, setCurrentSubCategory] = useState<string>("");
  const [debouncedValue] = useDebounce(searchValue, 500);
  const defineCurrentOffset = () => {
    const res = products.length && currentOffset >= 0 ? currentOffset + 1 : 0;
    return res.toString();
  };

  const onChangeItemsCount = (value: string) => {
    setCurrentLimit(Number(value));
    setCurrentOffset(0);
  };

  const definePageCount = () => {
    return recordsCount;
  };

  const defineCurrentLimit = () => {
    const nextOffset = currentOffset + currentLimit;
    return (nextOffset > recordsCount ? recordsCount : nextOffset).toString();
  };

  const definePrefPage = () => {
    const newOffset = currentOffset - currentLimit;
    setCurrentOffset(newOffset < 0 ? 0 : newOffset);
  };

  const defineNextPage = (offset: string) => {
    const newOffset = currentOffset + currentLimit;
    if (newOffset < recordsCount) {
      setCurrentOffset(newOffset);
    }
  };

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const headDataTable = [
    {
      content: (
        <Checkbox
          checked={isAllSelected && !!products.length}
          onChange={(event) => selectAllProducts(event.target.checked)}
        ></Checkbox>
      ),
      className: "w-48",
    },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "w-360",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "w-71",
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Brand",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.BRAND === sortConfig.key,
      }),
      className: "w-120",
      sortKey: THEAD_SORT_KEYS.BRAND,
    },
    {
      content: generateTextColumn({
        text: "Subcategory",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.SUBCATEGORY === sortConfig.key,
      }),
      className: "w-140",
      sortKey: THEAD_SORT_KEYS.SUBCATEGORY,
    },
    {
      content: generateTextColumn({
        text: "Store",
        isSorted: true,
        sortDirection: sortConfig.direction || "",
        isActiveSort: THEAD_SORT_KEYS.STORE === sortConfig.key,
      }),
      className: "w-95",
      sortKey: THEAD_SORT_KEYS.STORE,
    },
  ];

  const dataTableBodyCompare = [
    {
      content: ({ id }: IProduct) => (
        <Checkbox
          checked={productsSelectedIds.some((el) => el === id)}
          onChange={(event) => {
            selectProduct(id, event?.target.checked);
          }}
        ></Checkbox>
      ),
    },
    {
      content: ({ article, name }: IProduct) => (
        <>
          <h5 className="product__subtitle">{article}</h5>
          <h5 className="product__title">{cutStringToLimit(name)}</h5>
        </>
      ),
      className: "w-430",
    },
    {
      content: ({ imageUrl }: IProduct) => (
        <div>
          <img alt="img-url" className="product__img" src={imageUrl} />
        </div>
      ),
    },
    {
      content: ({ currentPrice }: IProduct) => (
        <h5 className="ta-right">${currentPrice}</h5>
      ),
    },
    {
      content: ({ currentMargin = "0" }: IProduct) => <h5>{currentMargin}%</h5>,
      className: "ta-right",
    },
    {
      content: ({ cost }: IProduct) => <h5>${cost}</h5>,
    },
    {
      content: ({ brand }: IProduct) => <h5>{brand}</h5>,
    },
    {
      content: ({ subcategory }: IProduct) => <h5>{subcategory}</h5>,
    },
    {
      content: ({ store }: IProduct) => <h5>{store}</h5>,
    },
  ];

  const dataTableBody = products.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: productsSelectedIds.some((id) => id === item.id),
      className: el.className,
      item: item,
    }));
  });

  useEffect(() => {
    const chooseCurrentBrand =
      currentBrand === DEFAULT_OPTION_SELECT_STRING ? null : currentBrand;
    const chooseCurrentSubCategory =
      currentSubCategory === DEFAULT_OPTION_SELECT_STRING
        ? null
        : currentSubCategory;

    const data = {
      [ORDER_BY_PARAM]: sortConfig.key,
      [DIRECTION_PARAM]: sortConfig.direction,
      [LIMIT_PARAM]: currentLimit,
      [OFFSET_PARAM]: currentOffset,
      [CATEGORY_PARAM]: currentCategory,
      [GROUP_ID_PARAM]: id,
      [BRAND_PARAM]: chooseCurrentBrand,
      [SUBCATEGORY_PARAM]: chooseCurrentSubCategory,
      [SEARCH_PARAM]: debouncedValue,
    };

    const filteredData = filteredCompareQueryObj(data) as IDataTransfer;

    if (currentCategory && flag) {
      getProducts(filteredData);
    }
  }, [
    currentCategory,
    sortConfig.key,
    sortConfig.direction,
    currentLimit,
    currentOffset,
    currentBrand,
    currentSubCategory,
    debouncedValue,
    flag,
  ]);

  useEffect(() => {
    return () => {
      setProducts([], 0);
      if (!productsInGroup.length) {
        setCurrentCategory("");
      }
      setProductsSelectedIds([]);
      setSortConfig({ key: "", direction: undefined });
    };
  }, [flag]);

  const onClose = () => {
    setItemsPopupShow(false);
    setProducts([], 0);
  };

  const addProductsToGroup = async () => {
    const res = await saveEmptyGroupWithProducts(
      productsSelectedIds,
      id || null,
      currentCategory || ""
    );
    setItemsPopupShow(false);
    setProducts([], 0);
    return res;
  };

  const isCorrectDiapason = (currentMargin: number) => {
    const { max, min } = priceLimits;
    const maxPricingValue = +max?.pricingValue || "";
    const minPricingValue = +min?.pricingValue || "";

    if (
      max?.pricing === SelectLimitPricing.MARGIN &&
      min?.pricing === SelectLimitPricing.MARGIN
    ) {
      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        minPricingValue > maxPricingValue
      ) {
        return false;
      }

      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        currentMargin > maxPricingValue &&
        currentMargin < minPricingValue
      ) {
        return true;
      }
    }
    if (max?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof maxPricingValue === "number" &&
        currentMargin > maxPricingValue
      ) {
        return true;
      } else return false;
    }
    if (min?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof minPricingValue === "number" &&
        currentMargin < minPricingValue
      ) {
        return true;
      } else return false;
    }

    return false;
  };

  const checkLengthLimitsForExceptions = (allExceptions: any) => {
    return allExceptions.filter((el: any) => {
      return isCorrectDiapason(+el.currentMargin);
    }).length;
  };

  const handleOk = async () => {
    const res = await addProductsToGroup();
    res && setIsModalInfoOpen(false);
    res && setItemsPopupShow(false);

    const data = {
      [GROUP_ID_PARAM]: id,
    };
    const exceptionsRes = await getAllExceptions(data);

    if (exceptionsRes) {
      const isWarningLimits =
        allExceptions && checkLengthLimitsForExceptions(exceptionsRes);

      if (
        strategyRule.competitors.length ||
        pricesFormula.metricToGrow ||
        pricesFormula.metricToMaintain
      ) {
        addAlertToStore("strategyRule", AlertType.WARNING);
      }

      if (isWarningLimits) {
        addAlertToStore("priceLimits", AlertType.WARNING);
      } else {
        useGroupsStore.setState((state) => {
          return {
            ...state,
            strategy: {
              ...state.strategy,
              priceLimits: {
                ...state.strategy.priceLimits,
                alerts: [],
              },
            },
          };
        });
      }
    }
  };

  const handleCancel = () => {
    setIsModalInfoOpen(false);
  };

  const handleChange = async () => {
    if (type) {
      setIsModalInfoOpen(true);
    } else {
      const res = await addProductsToGroup();
      const data = {
        [GROUP_ID_PARAM]: id,
      };
      const exceptionsRes = await getAllExceptions(data);
      res && exceptionsRes && setIsModalInfoOpen(false);
      res && exceptionsRes && setItemsPopupShow(false);
      if (
        strategyRule.competitors.length ||
        pricesFormula.metricToGrow ||
        pricesFormula.metricToMaintain
      ) {
        addAlertToStore("strategyRule", AlertType.WARNING);
      }
      if (Object.keys(priceLimits).length) {
        addAlertToStore("priceLimits", AlertType.WARNING);
      }
    }
  };

  const infoTextList = [
    {
      text: "Add new items to the current group",
    },
    {
      text: `Potentially affect your current settings in the "Strategy Rule" and "Price Limits" tabs`,
    },
  ];

  const modalInfoRender = () => (
    <Modal
      title={<div className="modal__title">Confirm Adding Items to Group</div>}
      open={isModalInfoOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <div key={1} className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={handleCancel}
            />

            <ButtonStyled
              type="primary"
              text="Confirm"
              htmlType="button"
              fill="gray-primary-900"
              className={`groups-empty__btn w-84`}
              onClick={handleOk}
              iconPosition="end"
            />
          </div>
        </div>,
      ]}
    >
      <div className="modal__container">
        <div className="confirm-modal-body-info">
          Are you sure you want to add
          <b>
            {` ${productsSelectedIds.length}`} selected{" "}
            {getItemWord(productsSelectedIds.length)}
          </b>
          ?
          <div>
            <span>This action will:</span>
            <ul>
              {infoTextList.map((el, ind) => (
                <li key={ind}>{el.text}</li>
              ))}
            </ul>
          </div>
          <div>
            After adding the items , you may need to review and adjust these
            settings.
          </div>
        </div>
      </div>
    </Modal>
  );

  const isShowProducts = products.length;

  return (
    <>
      {modalInfoRender()}

      <Modal
        open={flag}
        className="modal-products"
        footer={null}
        centered={true}
        onOk={() => {}}
        closeIcon={false}
        maskClosable={false}
      >
        <div className="modal__container">
          <div onClick={() => onClose()} className="modal-close__icon">
            <Close />
          </div>
          <h2 className="modal__title">
            Add Items
            <p className="subtitle__add-products">
              View available ungrouped items
            </p>
          </h2>
          <Divider />
          <div className="modal__content">
            <div className="modal__body">
              <ItemsPopupBulkAction
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                brand={currentBrand}
                subcategory={currentSubCategory}
                setCurrentBrand={setCurrentBrand}
                setCurrentSubCategory={setCurrentSubCategory}
                setCurrentCategory={setCurrentCategory}
                currentCategory={currentCategory}
              />
              {isShowProducts ? (
                <div className="products__table products__table-block">
                  <TableCustom
                    bodyData={dataTableBody}
                    headData={headDataTable}
                    handleSort={handleSort}
                  />
                </div>
              ) : null}
            </div>
            {isShowProducts ? (
              <>
                <div className="pagination__container">
                  <div className="selected__block mr-24">
                    <span className="selected__count">
                      {productsSelectedIds.length}
                    </span>
                    <span className="selected__text">
                      {getItemWord(productsSelectedIds.length)} selected
                    </span>
                  </div>

                  <Pagination
                    prevPage={definePrefPage}
                    nextPage={defineNextPage}
                    onChange={onChangeItemsCount}
                    recordsCount={definePageCount()}
                    currentLimit={defineCurrentLimit()}
                    currentSelectLimit={currentLimit.toString()}
                    currentOffset={defineCurrentOffset()}
                  />
                </div>
                <Divider />
              </>
            ) : (
              <div className="products__table noData__products">
                Select a category first to view items list
              </div>
            )}
            <div className="modal__footer">
              <div className="df jc-fe">
                <ButtonStyled
                  text="Cancel"
                  htmlType="button"
                  className="w-84 mr-16"
                  onClick={() => onClose()}
                />
                <ButtonStyled
                  type="primary"
                  text="Add"
                  htmlType="button"
                  fill="gray-primary-900"
                  className={`items__btn-next ${
                    productsSelectedIds.length ? "" : "disabled"
                  }`}
                  onClick={handleChange}
                  iconPosition="end"
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ItemsPopup;
