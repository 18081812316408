import { Divider, Modal } from "antd";
import "./addExceptionPopup.scss";
import { FC, useEffect, useState } from "react";
import { Circle, Close, Search } from "@shared/ui/assets";
import { ButtonStyled, InputCustom, RadioCustom } from "@shared/ui/components";
import { generateTextColumn, TableCustom } from "@shared/common";
import { IDataTransfer, ISortParams, SortParams } from "@shared/interfaces";
import { IProduct } from "@entities/products";
import { useParams } from "react-router-dom";
import { filteredCompareQueryObj } from "@shared/utils";
import { useDebounce } from "use-debounce";
import {
  DIRECTION_PARAM,
  GROUP_ID_PARAM,
  ORDER_BY_PARAM,
} from "@shared/constants";
import { SelectLimitPricing, useGroupsStore } from "@entities/groups";

interface IProps {
  isOpenModal: boolean;
  setIsOpenModal: (flag: boolean) => void;
}

enum THEAD_SORT_KEYS {
  NAME = "name",
  CURRENT_PRICE = "currentPrice",
  CURRENT_MARGIN = "currentMargin",
  COST = "cost",
}

const AddExceptionPopup: FC<IProps> = ({ isOpenModal, setIsOpenModal }) => {
  const { id } = useParams();

  const {
    strategy: { priceLimits },
    getAllExceptions,
    allExceptions,
    setProductInGroupIdRadioSelected,
    productInGroupIdRadioSelected,
    addExceptionToGroup,
  } = useGroupsStore((state) => state);
  const [searchValue, setSearchValue] = useState<string>("");
  const [debouncedValue] = useDebounce(searchValue, 500);
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams | "";
  }>({
    key: "",
    direction: "",
  });

  useEffect(() => {
    const data = {
      [GROUP_ID_PARAM]: id,
      [DIRECTION_PARAM]: sortConfig.direction,
      [ORDER_BY_PARAM]: sortConfig.key,
      search: debouncedValue,
    };
    const queryObject = filteredCompareQueryObj(data) as IDataTransfer;
    if (id && isOpenModal) {
      getAllExceptions(queryObject);
    }
  }, [sortConfig, debouncedValue, isOpenModal]);

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const headDataTable = [
    {
      content: <></>,
      className: "table-w-16",
    },
    {
      content: generateTextColumn({
        text: "Title & SKU",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "w-360",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({ text: "Image" }),
      className: "w-71",
    },
    {
      content: generateTextColumn({
        text: "Cost",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.COST === sortConfig.key,
      }),
      className: "w-90",
      sortKey: THEAD_SORT_KEYS.COST,
    },
    {
      content: generateTextColumn({
        text: "Current price",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.CURRENT_PRICE === sortConfig.key,
      }),
      className: "w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_PRICE,
    },
    {
      content: generateTextColumn({
        text: "Current margin",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.CURRENT_MARGIN === sortConfig.key,
      }),
      className: "w-109",
      sortKey: THEAD_SORT_KEYS.CURRENT_MARGIN,
    },
  ];

  const isCorrectDiapason = (currentMargin: number) => {
    const { max, min } = priceLimits;
    const maxPricingValue = +max?.pricingValue || "";
    const minPricingValue = +min?.pricingValue || "";

    if (
      max?.pricing === SelectLimitPricing.MARGIN &&
      min?.pricing === SelectLimitPricing.MARGIN
    ) {
      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        minPricingValue > maxPricingValue
      ) {
        return false;
      }

      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        currentMargin > maxPricingValue &&
        currentMargin < minPricingValue
      ) {
        return true;
      }
    }
    if (max?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof maxPricingValue === "number" &&
        currentMargin > maxPricingValue
      ) {
        return true;
      } else return false;
    }
    if (min?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof minPricingValue === "number" &&
        currentMargin < minPricingValue
      ) {
        return true;
      } else return false;
    }

    return false;
  };

  const dataTableBodyCompare = [
    {
      content: ({ id }: IProduct) => (
        <RadioCustom
          onChange={() => {
            setProductInGroupIdRadioSelected(id);
          }}
          checked={productInGroupIdRadioSelected === id}
        />
      ),
    },
    {
      content: ({ article, name }: IProduct) => (
        <>
          <h5 className="product__suptitle">{article}</h5>
          <h5 className="product__title">{name}</h5>
        </>
      ),
      className: () => "w-360",
    },
    {
      content: ({ imageUrl }: IProduct) => (
        <div>
          <img alt="img-url" className="product__img" src={imageUrl} />
        </div>
      ),
    },
    {
      content: ({ cost }: IProduct) => <h5>${cost}</h5>,
      className: () => "ta-right",
    },
    {
      content: ({ currentPrice }: IProduct) => (
        <h5 className="ta-right">${currentPrice}</h5>
      ),
    },
    {
      content: ({ currentMargin = "0" }: IProduct) => {
        return (
          <div>
            <h5>
              {isCorrectDiapason(+currentMargin) && (
                <span className="mr-8">
                  <Circle />
                </span>
              )}
              {currentMargin}%
            </h5>
          </div>
        );
      },
      className: ({ currentMargin }: IProduct) =>
        `ta-right ${isCorrectDiapason(+currentMargin) && "suitable-active"}`,
    },
  ];

  const dataTableBody = allExceptions.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: productInGroupIdRadioSelected == item.id,
      className: el?.className ? el?.className(item) : "",
      item: item,
    }));
  });

  const handleChangeInput = (target: any) => {
    const value = target.value;
    setSearchValue(value);
  };

  const onClose = () => {
    setIsOpenModal(false);
    setProductInGroupIdRadioSelected("");
    setSearchValue("");
  };

  const handleClickAdd = async () => {
    await addExceptionToGroup();
    onClose();
  };

  return (
    <>
      <Modal
        open={isOpenModal}
        className="modal-exceptions"
        centered={true}
        onOk={() => {}}
        closeIcon={false}
        maskClosable={false}
        footer={[
          <div key={1} className="groups__form-content">
            <div className="df jc-fe">
              <ButtonStyled
                text="Cancel"
                htmlType="button"
                className="w-84 mr-16"
                onClick={onClose}
              />

              <ButtonStyled
                type="primary"
                text="Add"
                htmlType="button"
                fill="gray-primary-900"
                className={`w-84 ${
                  !productInGroupIdRadioSelected && "disabled"
                }`}
                onClick={handleClickAdd}
              />
            </div>
          </div>,
        ]}
      >
        <div className="modal__header-exceptions">
          <div onClick={() => onClose()} className="modal-close__icon">
            <Close />
          </div>
          <h2 className="modal__title">
            Add exception
            <p className="subtitle__add-products">
              Select a specific item to set a custom price limit
            </p>
          </h2>
          <Divider />
        </div>
        <div className="modal__exceptions-content">
          <div className="df jc-sb modal__search-block">
            <InputCustom
              type="text"
              value={searchValue}
              name="group-search"
              placeholder="Search for Items"
              onChangeInput={({ target }) => handleChangeInput(target)}
              fill="gray-primary-900"
              iconRight={<Search />}
            />
            <div className="df ai-center">
              <span className="mr-8">
                <Circle /> suggested restrictions
              </span>
            </div>
          </div>
          <div className="modal-exceptions__table">
            <TableCustom
              headData={headDataTable}
              bodyData={dataTableBody}
              handleSort={handleSort}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddExceptionPopup;
