import { FC } from "react";
import "./badge.scss";

import { Typography } from "antd";

const { Title } = Typography;

interface IProps {
  count: string | number;
  fill?: string;
  className?: string;
}

const Badge: FC<IProps> = ({ count, className: badgeClasses, fill="" }: IProps) => {
  const classes = ["badge__wraper"];
  classes.push(fill);

  if (badgeClasses) {
    classes.push(badgeClasses);
  }

  return (<div className={classes.join(" ")}>
    <Title level={5} className="">
      {count}
    </Title>
  </div>)
}

export default Badge;