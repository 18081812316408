import { useEffect, useState } from "react";
import "./groupWidget.scss";
import moment from "moment";
import type { MenuProps } from "antd";
import { url } from "@shared/utils/url.utils";
import { Link, useSearchParams } from "react-router-dom";
import { GroupsDeletePopup } from "../../GroupsDeletePopup";
import { AsideDropdown } from "@widgets/admin/asideDropdown";
import { Dots } from "@shared/ui/assets";
import {
  EGroupType,
  IGroup,
  IGroupState,
  useGroupsStore,
} from "@entities/groups";
import { Status, Badge as CustomBadge } from "@shared/ui/components";
import { GroupsEditPopup } from "../../GroupsEditPopup";
import { getMenuPropsItems } from "@shared/common/GetMenuPropsItems";
import { generateTextColumn } from "@shared/common";
import { ISortParams, ROUTES, SortParams } from "@shared/interfaces";
import TableCustom from "@shared/common/ui/TableCustom/TableCustom";
import {
  DIRECTION_PARAM,
  FILTER_BY_PARAM,
  LIMIT_PARAM,
  OFFSET_PARAM,
  ORDER_BY_PARAM,
  SEARCH_PARAM,
} from "@shared/constants";

type MenuItem = Required<MenuProps>["items"][number];

enum THEAD_SORT_KEYS {
  NAME = "name",
  STATUS = "status",
  REPRICED_ITEMS = "repricedItems",
  TOTAL_ITEMS = "totalItems",
  UPDATED_AT = "updated_at",
}

const GroupsWidget = () => {
  const [isDeleteGroupPopupShow, setDeleteGroupPopupShow] =
    useState<boolean>(false);
  const [isEditGroupPopupShow, setEditGroupPopupShow] =
    useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: ISortParams;
  }>({
    key: searchParams.get(ORDER_BY_PARAM) || "",
    direction:
      (searchParams.get(DIRECTION_PARAM) as SortParams) || SortParams.ASC,
  });

  const { selectGroup, selectAllGroup, setCurrentGroup, newPricesAvailable } =
    useGroupsStore((state: IGroupState) => state);

  const groups = useGroupsStore((state: IGroupState) => state.groups);
  const { removeGroup } = useGroupsStore((state: IGroupState) => state);
  const isAllSelected = groups.some((group: IGroup) => !group.isSelected);

  useEffect(() => {
    const MIN_OFFSET = 10;

    if (!groups.length) {
      const searchObjectParams = url.getSearchParams(searchParams);
      let offset = +searchObjectParams[OFFSET_PARAM];
      const limit = +searchObjectParams[LIMIT_PARAM];

      if (offset >= MIN_OFFSET) {
        offset -= limit;
      }

      //addition check
      if (offset < 0) {
        offset = 0;
      }

      searchParams.set(OFFSET_PARAM, offset.toString());
      setSearchParams(searchParams);
    }
  }, [groups.length]);

  const itemsTopMenu: MenuItem[] = [
    getMenuPropsItems(
      "Rename group",
      "rename",
      null,
      undefined,
      false,
      false,
      (e) => setEditGroupPopupShow(true)
    ),
    getMenuPropsItems("Export as CSV", "export", null, undefined, true),
    getMenuPropsItems(
      "Delete group",
      "delete",
      null,
      undefined,
      false,
      true,
      () => setDeleteGroupPopupShow(true)
    ),
  ];

  useEffect(() => {
    searchParams.set(DIRECTION_PARAM, sortConfig.direction);
    searchParams.set(ORDER_BY_PARAM, sortConfig.key);

    setSearchParams(searchParams);
  }, [sortConfig]);

  useEffect(() => {
    const searchObjectParams = url.getSearchParams(searchParams);
    if (!Object.keys(searchObjectParams).length) {
      searchParams.set(FILTER_BY_PARAM, "ALL");
      searchParams.set(LIMIT_PARAM, "10");
      searchParams.set(OFFSET_PARAM, "0");
      searchParams.set(SEARCH_PARAM, "");

      setSearchParams(searchParams);
    }
  }, [isAllSelected]);

  useEffect(() => {
    return () => {
      selectAllGroup(false);
    };
  }, []);

  const onCloseDeletePopupHandler = (flag: boolean) => {
    setDeleteGroupPopupShow(flag);
  };

  const dataTableTitles = [
    // {
    //   content: (
    //     <Checkbox
    //       checked={!isAllSelected && !!groups.length}
    //       onChange={(event) => selectAllGroup(event.target.checked)}
    //     />
    //   ),
    //   className: "w-48",
    // },
    {
      content: generateTextColumn({
        text: "Group name",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.NAME === sortConfig.key,
      }),
      className: "table-w-451",
      sortKey: THEAD_SORT_KEYS.NAME,
    },
    {
      content: generateTextColumn({
        text: "Reprice status",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.STATUS === sortConfig.key,
      }),
      className: "table-w-151",
      sortKey: THEAD_SORT_KEYS.STATUS,
    },
    {
      content: generateTextColumn({
        text: "Reprice items",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.REPRICED_ITEMS === sortConfig.key,
        tooltipText: "Items ready for repricing",
      }),
      className: "table-w-166",
      sortKey: THEAD_SORT_KEYS.REPRICED_ITEMS,
    },
    {
      content: generateTextColumn({
        text: "Total items",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.TOTAL_ITEMS === sortConfig.key,
      }),
      className: "table-w-130",
      sortKey: THEAD_SORT_KEYS.TOTAL_ITEMS,
    },
    {
      content: generateTextColumn({
        text: "Last repriced",
        isSorted: true,
        sortDirection: sortConfig.direction,
        isActiveSort: THEAD_SORT_KEYS.UPDATED_AT === sortConfig.key,
      }),
      className: "table-w-166",
      sortKey: THEAD_SORT_KEYS.UPDATED_AT,
    },
    {
      content: null,
      className: "table-w-52 ",
    },
  ];

  const handleSort = (key: string) => {
    let direction: ISortParams = SortParams.ASC;
    if (sortConfig.key === key) {
      direction =
        sortConfig.direction === SortParams.ASC
          ? SortParams.DESC
          : SortParams.ASC;
    }
    setSortConfig({ key, direction });
  };

  const isDraftType = (type: EGroupType) => type === EGroupType.DRAFT;

  const dataTableBodyCompare = [
    // {
    //   content: ({ isSelected, id }: IGroup) => {
    //     return (
    //       <Checkbox
    //         checked={isSelected}
    //         onChange={(event) => selectGroup(id, event?.target.checked)}
    //       ></Checkbox>
    //     );
    //   },
    // },
    {
      content: ({ name, type, id }: IGroup) => {
        const chooseUrl =
          EGroupType.DRAFT === type
            ? `${ROUTES.ADMIN_GROUPS}/create/${id}`
            : "";
        return (
          <div className="df ai-center group-name__link">
            <Link to={chooseUrl}>{name}</Link>
            <CustomBadge
              count={type.toLowerCase()}
              className="ml-8 group-name__bagde"
            />
          </div>
        );
      },
      className: "table-w-451",
    },
    {
      content: ({ id, status, type }: IGroup) => {
        const render = !isDraftType(type) ? (
          <Status key={`status-${id}`} type={status} />
        ) : (
          <></>
        );
        return render;
      },
    },
    {
      content: ({ repricedItems, type }: IGroup) => {
        const render = !isDraftType(type) ? (
          <div className="reprice-badge__table">
            <CustomBadge
              count={repricedItems}
              fill={repricedItems > 0 ? "gray-600" : ""}
              className="mr-16"
            />
          </div>
        ) : (
          <></>
        );
        return render;
      },
    },
    {
      content: ({ totalItems, type }: IGroup) => {
        const render = !isDraftType(type) ? (
          <div className="total-items__table">
            <h5 className="pr-16">{totalItems}</h5>
          </div>
        ) : (
          <></>
        );
        return render;
      },
      className: "ta-right",
    },
    {
      content: ({ updated_at, type }: IGroup) => {
        const chooserDate = updated_at ? (
          moment(updated_at).format("MMM D, YYYY, HH:mm")
        ) : (
          <div className="no-date">N/A</div>
        );
        const render = !isDraftType(type) ? chooserDate : <></>;

        return render;
      },
    },
    {
      content: () => {
        return (
          <AsideDropdown
            collapsed={true}
            items={itemsTopMenu}
            placement="bottomRight"
            className="relu__menu"
          >
            <div>
              <Dots color="#6C757D" />
            </div>
          </AsideDropdown>
        );
      },
    },
  ];

  const dataTableBody = groups.map((item) => {
    return dataTableBodyCompare.map((el) => ({
      element: el.content(item),
      isSelected: item.isSelected,
      className: el.className,
      item: item,
    }));
  });

  // const delAllGroups = async () => {
  //   await Promise.all(groups.map((group) => removeGroup(group.id)));
  // };

  return (
    <div
      className={`table__padding table__scroll ${
        newPricesAvailable.length ? "table__scroll-notification" : ""
      }`}
    >
      {/* <button onClick={delAllGroups}>del all groups</button> */}
      <GroupsDeletePopup
        flag={isDeleteGroupPopupShow}
        onClose={onCloseDeletePopupHandler}
      />
      <GroupsEditPopup
        flag={isEditGroupPopupShow}
        onClose={setEditGroupPopupShow}
      />

      <div className="wrapper-table">
        <TableCustom
          headData={dataTableTitles}
          bodyData={dataTableBody}
          handleSort={handleSort}
          onRowEvent={setCurrentGroup}
        />
      </div>
    </div>
  );
};

export default GroupsWidget;
