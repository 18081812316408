export const DEFAULT_LIMIT_PAGINATION = 10;
export const DEFAULT_OFFSET_PAGINATION = 0;

export const DEFAULT_OPTION_SELECT_STRING = "All";

export const PATH_PARAM = "path";
export const PRODUCTS_PARAM = "products";
export const CATEGORY_PARAM = "category";
export const SEARCH_PARAM = "search";
export const DIRECTION_PARAM = "direction";
export const ORDER_BY_PARAM = "orderBy";
export const OFFSET_PARAM = "offset";
export const LIMIT_PARAM = "limit";
export const FILTER_BY_PARAM = "filterBy";
export const BRAND_PARAM = "brand";
export const SUBCATEGORY_PARAM = "subcategory";
export const GROUP_ID_PARAM = "groupId";
export const MODIFY_PARAM = "modify";
