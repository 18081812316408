import { PriceRoundingEnum } from "@entities/groups";

export const getItemWord = (count: number): string => {
  return count === 1 ? "item" : "items";
};

export const getCompetitorWord = (count: number): string => {
  return count === 1 ? "competitor" : "competitors";
};

export const filteredCompareQueryObj = (compareQueryObj: any) => {
  return Object.fromEntries(
    Object.entries(compareQueryObj).filter(
      ([key, value]) => value !== undefined && value !== null && value !== ""
    )
  );
};

interface IAjustPriceValue {
  decimalMain?: number | null;
  integerMain?: number | null;
  round?: PriceRoundingEnum;
  baseNum?: number;
}

export const adjustPriceValue = ({
  decimalMain = null,
  integerMain = null,
  round = PriceRoundingEnum.ROUND_DOWN,
  baseNum = 123.43,
}: IAjustPriceValue) => {
  let result;
  const [integer, decimal] = baseNum.toString().split(".");
  if (decimalMain && integerMain) {
    const [a, b] = integer.toString().split("");
    // const chooseDecimal = `${decimalMain}`.length === 1 ? `${decimalMain}0` : `${decimalMain}`

    if (round === PriceRoundingEnum.ROUND_DOWN) {
      if (integerMain < +integer % 10) {
        return [a, b, integerMain].join("") + "." + decimalMain;
      }
      if (integerMain > +integer % 10) {
        return [a, +b - 1, integerMain].join("") + "." + decimalMain;
      }
    }
    if (round === PriceRoundingEnum.ROUND_UP) {
      if (integerMain < +integer % 10) {
        return [+a, +b + 1, integerMain].join("") + "." + decimalMain;
      }
      if (integerMain > +integer % 10) {
        return [a, b, integerMain].join("") + "." + decimalMain;
      }
    }
  }

  if (integerMain) {
    const [a, b] = integer.toString().split("");
    if (round === PriceRoundingEnum.ROUND_DOWN) {
      if (integerMain < +integer % 10) {
        return [a, b, integerMain].join("") + "." + decimal;
      }
      if (integerMain > +integer % 10) {
        return [a, +b - 1, integerMain].join("") + "." + decimal;
      }
    }
    if (round === PriceRoundingEnum.ROUND_UP) {
      if (integerMain < +integer % 10) {
        return [+a, +b + 1, integerMain].join("") + "." + decimal;
      }
      if (integerMain > +integer % 10) {
        return [a, b, integerMain].join("") + "." + decimal;
      }
    }
  }
  if (decimalMain) {
    const [a, b, c] = integer.toString().split("");
    if (round === PriceRoundingEnum.ROUND_DOWN) {
      if (decimalMain < +decimal) {
        return `${integer}.${decimalMain}`;
      }
      if (decimalMain > +decimal) {
        return [a, b, +c - 1].join("") + "." + decimalMain;
      }
    }
    if (round === PriceRoundingEnum.ROUND_UP) {
      if (decimalMain < +decimal) {
        return [a, b, +c + 1].join("") + "." + decimalMain;
      }
      if (decimalMain > +decimal) {
        return `${integer}.${decimalMain}`;
      }
    }
  }

  return baseNum;
};
