import { ThemeConfig } from "antd";

export const themeDefault: ThemeConfig = {
  token: {
    fontSize: 16,
    colorPrimary: "#00b96b!important",
  },
  components: {
    Button: {},

    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
  },
};
