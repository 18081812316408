import { Alert, Divider, Select } from "antd";
import {
  AlertCustom,
  ButtonStyled,
  CheckboxStyled,
  SwitcherCustom,
} from "@shared/ui/components";
import { ArrowBorder, Delete, Plus, Warning } from "@shared/ui/assets";
import { AddExceptionPopup } from "../../../AddExceptionPopup";
import { useEffect, useState } from "react";
import {
  AlertType,
  CurrentStep,
  IProductException,
  LimitMSRP,
  SelectLimitPricing,
  useGroupsStore,
} from "@entities/groups";
import { IProduct, useProductsStore } from "@entities/products";
import InputWithIcons from "@shared/ui/components/inputWithIcons/InputWithIcons";
import ItemException from "./ItemException/ItemException";
import "./priceLimits.scss";

const priceLimitsArr = [
  {
    text: "Set minimum price limit based on",
    currentPriceIcon: "-",
    key: "min",
    inputClassName: "inputClassNameCenter",
  },
  {
    text: "Set maximum price limit based on",
    currentPriceIcon: "+",
    key: "max",
    inputClassName: "inputClassNameCenter",
  },
];

const optionsSelect = [
  {
    label: "Current price",
    value: SelectLimitPricing.CURRENT_PRICE,
  },
  {
    label: "Margin",
    value: SelectLimitPricing.MARGIN,
  },
  {
    label: "MSRP",
    value: SelectLimitPricing.MSRP,
  },
];

const switcherData = [
  { key: LimitMSRP.PLUS, content: <>+</> },
  { key: LimitMSRP.MINUS, content: <>-</> },
];

const PriceLimits = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { priceLimits } = useGroupsStore((state) => state.strategy);
  const {
    getExceptions,
    exceptionProducts,
    allExceptions,
    setValidationError,
    addAlertToStore,
  } = useGroupsStore((state) => state);
  const { validationErrors } = useGroupsStore((state) => state);
  const handleClickAddException = () => {
    setIsOpenModal(true);
  };

  const handleClick = (key: string) => {
    const diffKey = key === "min" ? "max" : "min";
    let isCheck = false;

    useGroupsStore.setState((state) => {
      isCheck = !state.strategy.priceLimits[key]?.active || false;
      return {
        strategy: {
          ...state.strategy,
          priceLimits: {
            ...state.strategy.priceLimits,
            [key]: {
              active: !state.strategy.priceLimits[key]?.active || false,
            },
            alerts: [],
          },
        },
        keyOfStrategy: CurrentStep.PRICE_LIMITS,
      };
    });

    const priceLimitsObj = {
      ...(useGroupsStore.getState().validationErrors as Record<string, any>)[
        CurrentStep.PRICE_LIMITS
      ],
    };

    if (isCheck) {
      return;
    }

    const updatedPriceLimitsObj = {
      ...priceLimitsObj,
      [`${key}Input`]: null,
      [`${key}Select`]: null,
      [`${diffKey}Input`]: {
        ...priceLimitsObj[`${diffKey}Input`],
      },
    };

    if (!updatedPriceLimitsObj[`${diffKey}Input`]?.value) {
      updatedPriceLimitsObj[`${diffKey}Input`] = null;
    }

    if (updatedPriceLimitsObj[`${diffKey}Input`]?.range) {
      delete updatedPriceLimitsObj[`${diffKey}Input`].range;
    }

    setValidationError({
      [CurrentStep.PRICE_LIMITS]: updatedPriceLimitsObj,
    });
  };

  const isActiveCheckbox = (key: string) => {
    return priceLimits[key]?.active || false;
  };

  const isActivePricing = (key: string) => {
    return priceLimits[key]?.pricing || false;
  };

  const handleChooseSelect = (key: string, value: string) => {
    useGroupsStore.setState((state) => {
      return {
        strategy: {
          ...state.strategy,
          priceLimits: {
            ...state.strategy.priceLimits,
            [key]: {
              ...state.strategy.priceLimits[key],
              pricing: value,
            },
            alerts: [],
          },
        },
        keyOfStrategy: CurrentStep.PRICE_LIMITS,
      };
    });
  };

  const handleChangeInput = (key: string, value: string) => {
    const isValidValue = /^\d{0,2}(\.\d{0,2})?$/.test(value);
    if (isValidValue) {
      useGroupsStore.setState((state) => {
        return {
          strategy: {
            ...state.strategy,
            priceLimits: {
              ...state.strategy.priceLimits,
              [key]: {
                ...state.strategy.priceLimits[key],
                pricingValue: value,
              },
              alerts: [],
            },
          },
          keyOfStrategy: CurrentStep.PRICE_LIMITS,
        };
      });
    }
  };
  const handleChangeSwitcher = (key: string, value: LimitMSRP) => {
    useGroupsStore.setState((state) => {
      return {
        strategy: {
          ...state.strategy,
          priceLimits: {
            ...state.strategy.priceLimits,
            [key]: {
              ...state.strategy.priceLimits[key],
              metric: value,
            },
            alerts: [],
          },
        },
        keyOfStrategy: CurrentStep.PRICE_LIMITS,
      };
    });
  };

  useEffect(() => {
    if (!isOpenModal) {
      getExceptions();
    }
  }, [isOpenModal]);

  const minInputError = validationErrors[CurrentStep.PRICE_LIMITS]?.minInput;
  const maxInputError = validationErrors[CurrentStep.PRICE_LIMITS]?.maxInput;

  const minSelectError = validationErrors[CurrentStep.PRICE_LIMITS]?.minSelect;
  const maxSelectError = validationErrors[CurrentStep.PRICE_LIMITS]?.maxSelect;

  const isErrorClassInput = (key: string) => {
    if (key === "min" && minInputError) {
      return "error-validation-input";
    }
    if (key === "max" && maxInputError) {
      return "error-validation-input";
    }
    return "";
  };
  const isErrorClassSelect = (key: string) => {
    if (key === "min" && minSelectError) {
      return "error-validation-select";
    }
    if (key === "max" && maxSelectError) {
      return "error-validation-select";
    }

    return "";
  };
  const isErrorInput = (key: string) => {
    if (key === "min" && minInputError) {
      return true;
    }
    if (key === "max" && maxInputError) {
      return true;
    }
    return "";
  };
  const isErrorSelect = (key: string) => {
    if (key === "min" && minSelectError) {
      return true;
    }
    if (key === "max" && maxSelectError) {
      return true;
    }
    return "";
  };

  const getTextError = () => {
    const isMaxInputErrorRange =
      validationErrors[CurrentStep.PRICE_LIMITS]?.maxInput?.range;
    const isMinInputErrorRange =
      validationErrors[CurrentStep.PRICE_LIMITS]?.minInput?.range;
    const isMaxInputErrorValue =
      validationErrors[CurrentStep.PRICE_LIMITS]?.maxInput?.value;
    const isMinInputErrorValue =
      validationErrors[CurrentStep.PRICE_LIMITS]?.minInput?.value;
    if (isMaxInputErrorRange && isMinInputErrorRange) {
      return `Logical mismatch. Please check and fix.`;
    }
    if (isMinInputErrorValue || isMaxInputErrorValue) {
      return `Please enter a value.`;
    }
    return "";
  };

  const metricActiveIndex = (key: string) => {
    return switcherData.findIndex(
      (el) => el.key === priceLimits[key]?.metric
    ) >= 0
      ? switcherData.findIndex((el) => el.key === priceLimits[key]?.metric)
      : 0;
  };
  const isCorrectDiapason = (currentMargin: number) => {
    const { max, min } = priceLimits;
    const maxPricingValue = +max?.pricingValue || "";
    const minPricingValue = +min?.pricingValue || "";

    if (
      max?.pricing === SelectLimitPricing.MARGIN &&
      min?.pricing === SelectLimitPricing.MARGIN
    ) {
      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        minPricingValue > maxPricingValue
      ) {
        return false;
      }

      if (
        typeof maxPricingValue === "number" &&
        typeof minPricingValue === "number" &&
        currentMargin > maxPricingValue &&
        currentMargin < minPricingValue
      ) {
        return true;
      }
    }
    if (max?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof maxPricingValue === "number" &&
        currentMargin > maxPricingValue
      ) {
        return true;
      } else return false;
    }
    if (min?.pricing === SelectLimitPricing.MARGIN) {
      if (
        typeof minPricingValue === "number" &&
        currentMargin < minPricingValue
      ) {
        return true;
      } else return false;
    }

    return false;
  };

  const checkLengthLimitsForExceptions = () => {
    return allExceptions.filter((el) => {
      return isCorrectDiapason(+el.currentMargin);
    }).length;
  };

  const messageText = (
    <>
      <div>
        <b>{`${checkLengthLimitsForExceptions()}`} item</b> eligible for
        exception based on entered Margin.
      </div>
      <div>Click 'Add exception' to view suggested restrictions.</div>
    </>
  );

  useEffect(() => {
    const isWarningLimits = checkLengthLimitsForExceptions();

    if (isWarningLimits) {
      addAlertToStore("priceLimits", AlertType.WARNING);
    } else {
      useGroupsStore.setState((state) => {
        return {
          ...state,
          strategy: {
            ...state.strategy,
            priceLimits: {
              ...state.strategy.priceLimits,
              alerts: [],
            },
          },
        };
      });
    }
  }, [priceLimits?.max?.pricingValue, priceLimits?.min?.pricingValue]);

  const onCloseWarningAlert = () => {
    console.log("close");
  };

  return (
    <>
      <AddExceptionPopup
        setIsOpenModal={setIsOpenModal}
        isOpenModal={isOpenModal}
      />
      <div className="groups-price-limits__wrapper content__container">
        <h3 className="groups-price-limits__title">Price Limits</h3>
        <h5 className="groups-price-limits__subtitle">
          Adjust strategy with price limits
        </h5>
        <div className="groups-price-limits__content">
          <h4 className="groups-price-limits__text">
            Set price limits for all items
          </h4>
          <div className="groups-price-limits__check-block">
            {priceLimitsArr.map(
              ({ text, currentPriceIcon, key, inputClassName }, ind) => (
                <div
                  key={ind}
                  className="df ai-center mt-24 groups-price-limits-block"
                >
                  <div className="groups-price-limits__check ">
                    <CheckboxStyled
                      checked={isActiveCheckbox(key)}
                      text={text}
                      onChange={() => handleClick(key)}
                      key={text}
                    />
                  </div>
                  {isActiveCheckbox(key) && (
                    <div className="groups-price-limits__select-wrapper">
                      <Select
                        onChange={(value) => handleChooseSelect(key, value)}
                        suffixIcon={<ArrowBorder color="#212527" />}
                        value={priceLimits[key]?.pricing || null}
                        options={optionsSelect}
                        placeholder="Select limit"
                        className={`custom-arrow groups-price-limits__select ${isErrorClassSelect(
                          key
                        )}`}
                      />
                      {isErrorSelect(key) && (
                        <div className="error-validation-message mr-16 ">
                          Please select a limit
                        </div>
                      )}
                    </div>
                  )}
                  {isActivePricing(key) &&
                    priceLimits[key]?.pricing === SelectLimitPricing.MARGIN && (
                      <div>
                        <InputWithIcons
                          wrapperClassName={`${isErrorClassInput(key)}`}
                          classNameInput={`${inputClassName} groups-price-limits__input`}
                          rightIcon="%"
                          onChange={(value: string) =>
                            handleChangeInput(key, value)
                          }
                          value={
                            priceLimits[key]?.pricingValue?.toString() || ""
                          }
                        />
                        {isErrorInput(key) && (
                          <div className="error-validation-message">
                            {getTextError()}
                          </div>
                        )}
                      </div>
                    )}
                  {isActivePricing(key) &&
                    priceLimits[key]?.pricing ===
                      SelectLimitPricing.CURRENT_PRICE && (
                      <div>
                        <InputWithIcons
                          wrapperClassName={`${isErrorClassInput(key)}`}
                          classNameInput={`${inputClassName} groups-price-limits__input-current-price`}
                          leftIcon={currentPriceIcon}
                          rightIcon="%"
                          onChange={(value: string) =>
                            handleChangeInput(key, value)
                          }
                          value={
                            priceLimits[key]?.pricingValue?.toString() || ""
                          }
                        />
                        {isErrorInput(key) && (
                          <div className="error-validation-message">
                            Please enter a value.
                          </div>
                        )}
                      </div>
                    )}
                  {isActivePricing(key) &&
                    priceLimits[key]?.pricing === SelectLimitPricing.MSRP && (
                      <>
                        <div className="groups-price-limits__switch">
                          <SwitcherCustom
                            activeIndex={metricActiveIndex(key)}
                            data={switcherData}
                            onClick={(value) =>
                              handleChangeSwitcher(key, value as LimitMSRP)
                            }
                          />
                        </div>
                        <div>
                          <InputWithIcons
                            wrapperClassName={`${isErrorClassInput(key)}`}
                            classNameInput={`${inputClassName} groups-price-limits__input-current-price`}
                            rightIcon="%"
                            onChange={(value: string) =>
                              handleChangeInput(key, value)
                            }
                            value={
                              priceLimits[key]?.pricingValue?.toString() || ""
                            }
                          />
                          {isErrorInput(key) && (
                            <div className="error-validation-message">
                              {getTextError()}
                            </div>
                          )}
                        </div>
                      </>
                    )}
                </div>
              )
            )}
          </div>
          {checkLengthLimitsForExceptions() ? (
            <div className="groups-price-limits__warning">
              <AlertCustom
                type="warning"
                message={messageText}
                icon={<Warning />}
                closable={true}
                onClose={() => {
                  onCloseWarningAlert();
                }}
              />
            </div>
          ) : null}
          <Divider className="divider" />
          <div className="df jc-sb ai-center groups-price-limits__exception-title-table">
            <h4 className="groups-price-limits__text">
              Set a custom price limit for specific items
            </h4>
            <div>
              <ButtonStyled
                text="Add exception"
                icon={<Plus color="#212529" />}
                onClick={handleClickAddException}
                iconPosition="start"
              />
            </div>
          </div>
          <div className="groups-price-limits__exception-table">
            {(exceptionProducts || [])?.map((product: IProductException) => (
              <ItemException product={product} key={product.id} />
            ))}
          </div>
          {!exceptionProducts.length ? (
            <div className="groups-price-limits__no-exception">
              No excepted items
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default PriceLimits;
