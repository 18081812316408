import "./strategyRule.scss";
import Competition from "./Competition/Competition";
import Elasticity from "./Elasticity/Elasticity";
import { StrategyTypeEnum, useGroupsStore } from "@entities/groups";

const StrategyRule = () => {
  const typeOfRule = useGroupsStore((state) => state.strategy.type);

  const renderObj = {
    [StrategyTypeEnum.ELASTICITY]: <Elasticity />,
    [StrategyTypeEnum.COMPETITION]: <Competition />,
  };

  if (!typeOfRule) {
    return <>No data</>;
  }

  return <>{renderObj[typeOfRule]}</>;
};

export default StrategyRule;
