import "./layoutWithAside.scss";
import { useEffect, useState } from "react";
import type { MenuProps } from "antd";
import { ROUTES } from "@shared/interfaces";
import { Layout, Typography, Popover, Divider } from "antd";
import { Link } from "react-router-dom";
import {
  Dots,
  Profile,
  GroupIcon,
  SettingsIcon,
  KnowledgeBase,
  Notification,
  ArrowBorder,
} from "@shared/ui/assets";
import { useUserStore } from "@entities/user";
import { useShallow } from "zustand/react/shallow";
import NotificationsAside from "./NotificationsAside";
import ProfilePopoverContent from "./ProfilePopoverContent";
import { Badge as CustomBadge } from "@shared/ui/components";

const { Title } = Typography;
const { Sider, Content } = Layout;

interface IProps {
  children: React.ReactElement;
}

type MenuItem = Required<MenuProps>["items"][number];

const LayoutWithAside = ({ children }: IProps) => {
  const collapseValue = localStorage.getItem("isAsideCollapsed") || false;
  const [collapsed, setCollapsed] = useState<boolean>(!!+collapseValue);

  const { logOut, getNotifications } = useUserStore((state) => state);
  const user = useUserStore(useShallow((state) => state.user));
  const notifications = useUserStore(
    useShallow((state) => state.notifications)
  );

  useEffect(() => {
    async function fetchAPI() {
      await getNotifications();
    }

    fetchAPI();
  }, []);

  const ProfilePopover = () => (
    <Popover
      content={<ProfilePopoverContent user={user} logOut={logOut} />}
      placement="right"
      trigger="click"
      arrow={false}
      overlayClassName="aside-popover__profile"
    >
      <div className="aside__item ">
        <div className="df ai-center">
          <div className="aside__item-ico">
            <Profile />
          </div>
          {collapsed && (
            <Title level={5} className="aside__link">
              Settings
            </Title>
          )}
        </div>
        {collapsed && (
          <div className="aside__actions">
            <Dots />
          </div>
        )}
      </div>
    </Popover>
  );

  const NotificationPopover = () => (
    <Popover
      content={<NotificationsAside />}
      placement="right"
      trigger="click"
      arrow={false}
      overlayClassName="aside-popover__notifications"
    >
      <div className="aside__item aside__margin-corection">
        <div className="aside__item-ico ">
          <Notification isShow={!!notifications.length} />
        </div>
        {collapsed && (
          <Title level={5} className="aside__link">
            Notifications
          </Title>
        )}
        {collapsed && (
          <CustomBadge
            count={notifications.length}
            className="aside__actions badge-default"
            fill="gray-600"
          />
        )}
      </div>
    </Popover>
  );

  const onCollapse = () => {
    const collapseValue = !collapsed ? 1 : 0;
    localStorage.setItem("isAsideCollapsed", String(collapseValue));

    setCollapsed(!collapsed);
  };

  const renderSider = () => {
    return (
      <div
        className={`${
          collapsed ? "aside__container" : "aside__container-active"
        }`}
      >
        <div className="demo-logo-vertical">
          <h4>ReLU</h4>
        </div>

        <div className="aside__menu">
          <div className="aside__menu-top">
            <div className="aside__item">
              <Link
                to={`${ROUTES.ADMIN_GROUPS}?filterBy=ALL&offset=0&limit=10`}
                className="aside__link"
              >
                <div className="aside__item-ico">
                  <GroupIcon />
                </div>
                {collapsed && <Title level={5}>Groups</Title>}
              </Link>
            </div>

            <div className="aside__item disabled">
              <Link to="/" className="aside__link">
                <div className="aside__item-ico">
                  <SettingsIcon />
                </div>
                {collapsed && <Title level={5}>Settings</Title>}
              </Link>
            </div>
          </div>

          <div className="aside__menu-bottom">
            <div className="aside__item disabled">
              <Link to={ROUTES.ADMIN_KNOWLEDGE_BASE} className="aside__link">
                <div className="aside__item-ico">
                  <KnowledgeBase />
                </div>
                {collapsed && " Knowledge Base"}
              </Link>
            </div>

            <NotificationPopover />
            <ProfilePopover />

            <Divider className="mb-8 mt-8" />

            <div
              className="aside__item aside__arrow"
              onClick={() => onCollapse()}
            >
              <div className="aside__item-ico aside__arrow">
                <ArrowBorder />
              </div>
              {collapsed && (
                <Title level={5} className="aside__link">
                  Collapse menu{" "}
                </Title>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="layout__container">
      {renderSider()}
      <Layout>
        <Content>{children}</Content>
      </Layout>
    </div>
  );
};

export default LayoutWithAside;
