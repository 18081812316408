import { FC } from "react";
import "./groupsDeletePopup.scss";
import { Divider, Modal } from "antd";
import { Typography } from "antd";
import { useGroupsStore } from "@entities/groups";
import { useShallow } from "zustand/react/shallow";
import { ButtonStyled } from "@shared/ui/components";
import { useSearchParams } from "react-router-dom";
import { url } from "@shared/utils/url.utils";
import { IQuery } from "@shared/interfaces";

const { Title } = Typography;

interface IProps {
  flag: boolean;
  onClose: (flag: boolean) => void;
}

const GroupsDeletePopup: FC<IProps> = ({ flag, onClose }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const currentGroup = useGroupsStore(
    useShallow((state) => state.currentGroup)
  );

  const { deleteGroup } = useGroupsStore((state) => state);

  const searchObjectParams = url.getSearchParams(searchParams);
  const onDeleteGroup = () => {
    deleteGroup(currentGroup, searchObjectParams as IQuery);
    onClose(false);
  };

  return (
    <Modal
      open={flag}
      onOk={() => {}}
      footer={null}
      maskClosable={false}
      closeIcon={false}
      centered={true}
      onCancel={() => onClose(false)}
    >
      <div className="groups__form-create">
        <Title level={2} className="groups__form-title">
          Delete Group
        </Title>
        <Divider />
        <Title level={5} className="groups__form-suptitle">
          This will delete {currentGroup.name}. This action will ungroup all
          items inside the group and unplug their strategies. You cannot undo
          this action.
        </Title>
        <Divider />
        <div className="groups__form-content">
          <div className="df jc-fe">
            <ButtonStyled
              text="Cancel"
              htmlType="button"
              className="w-84 mr-16"
              onClick={onClose}
            />

            <ButtonStyled
              text="Delete"
              type="primary"
              fill="red-600"
              htmlType="button"
              className="w-84"
              onClick={onDeleteGroup}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default GroupsDeletePopup;
